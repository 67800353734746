import React from 'react'
import Chart from "react-apexcharts";
import energyCategoryColors from '../energyCategoryColors';


export default function EnergyPieChart({data,height=350}) {
  const categoriesList = [
    "Oppvarming",
    "Varmtvann",
    "Vifter og pumper",
    "Belysning",
    "Teknisk utstyr",
    "Kjøling",
    "Utendørs",
    "Ukategorisert"
  ];
  const aggregatedData = categoriesList.reduce((acc, category) => {
    acc[category] = 0;
    return acc;
  }, {});

  data.forEach(building => {
    categoriesList.forEach(category => {
      const categoryValue = building.categories?.[category]?.value || 0;
      aggregatedData[category] += categoryValue;
    });
  });

  const series = Object.values(aggregatedData);
  const labels = Object.keys(aggregatedData);

  const chartOptions = {
    labels: labels,
    colors: energyCategoryColors,
    tooltip: {
        followCursor:false,
      y: {
        formatter: (val) => `${val.toFixed(0)} kWh`,
      },
    },
    noData: {
      text: "Laster inn",
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined
      }
    }
  };
  if(!series ){
    return(<></>)
  }
  return (
    <div>
      <Chart options={chartOptions} series={series} type="pie" height={height} />
    </div>
  );
 
}
