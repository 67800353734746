import React from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Link } from "react-router-dom";


function Login() {

    const {login} = useKindeAuth();

    return(
        <div id="loginBackground">
            <div className="overlay"></div>
            <div className="container-fluid h-100">
                <div className="row h-100 justify-content-between">
                    <div className="col-sm-4 my-auto">
                        <img src="/img/logo-centered-landscape-white.png" width="225px" height="auto" />
                    </div>

                    <div className="col-sm-4 my-auto">
                        <div className="login-card">
                            <div className="login-card-header">
                                <h2>Aquaflow</h2>
                                <p>Grunnlaget for gode beslutninger innen energistyring.</p>
                            </div>
                            
                            <div className="login-card-content">
                                <button className="login-button" onClick={login} type="button">Logg inn</button>
                                <Link className="register-button" to="https://insitemedia.kinde.com/knock-knock">Registrer konto</Link>
                            </div>

                            <div className="login-card-footer">
                                <p>Ved å fortsette så godtar du våre <Link to="http://aquaflow.no/kontakt/" target="_blank">vilkår</Link> og <Link to="http://aquaflow.no/kontakt/" target="_blank">personvern</Link>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}

export default Login;