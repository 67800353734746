import moment from 'moment';
import React, { useState, useEffect } from 'react'

export default function DeviceAlarmHistory({device}) {
  const [combinedHistory, setCombinedHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;

  useEffect(() => {
    if (device?.alarms) {
      const aggregatedHistory = device.alarms.flatMap(alarm =>
        alarm.alarmHistory.map(historyItem => ({
          ...historyItem,
          alarmName: alarm.name,
          alarmId: alarm._id
        }))
      );
      const sortedHistory = aggregatedHistory.sort((a, b) => new Date(b.triggeredAt) - new Date(a.triggeredAt));
      setCombinedHistory(sortedHistory);
    }
  }, [device]);

  const totalPages = Math.ceil(combinedHistory.length / recordsPerPage);

  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    const endIndex = Math.min(startIndex + recordsPerPage, combinedHistory.length);
    return combinedHistory.slice(startIndex, endIndex);
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`page-link ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className='alarm-history-wrapper'>
      {device?.alarms &&
        <div>
          <table className="infotable">
            <thead>
              <tr>
                <th>Alarmnavn</th>
                <th>Utløst</th>
                <th className="noright">Kvittert ut</th>
              </tr>
            </thead>
            <tbody>
              {getCurrentPageData().map((historyItem, index) => (
                <tr key={`${historyItem.alarmId}-history-${currentPage}-${index}`}>
                  <td>{historyItem.alarmName}</td>
                  <td>{moment(historyItem.triggeredAt).locale("NO").format('lll')}</td>
                  <td className="noright">{moment(historyItem.resolvedAt).locale("NO").format('lll')}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <span className="page-view">
            Side {currentPage} av {totalPages}
          </span>
          <div className="pagination-controls">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage <= 1}
            >
              <i className="fa-light fa-circle-chevron-left"></i>
            </button>
            {renderPageNumbers()}
            <button
              onClick={handleNextPage}
              disabled={currentPage >= totalPages}
            >
              <i className="fa-light fa-circle-chevron-right"></i>
            </button>
          </div>
        </div>
      }
    </div>
  );
}