import React from "react";
import '../src/App.css';

// Kinde authentication
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

// Global State for Date filter management
import { GlobalStateProvider } from "./GlobalStateContext";

// Use Route in order to define the different routes of our application
import { Route, Routes } from "react-router-dom";
 
// Import all the components we need in our app
import Login from "./pages/login";
import Navbar from "./components/navbar";
import RecordList from "./components/recordList";
import Edit from "./components/edit";
import Create from "./components/create";
import Callback from "./components/callback";
import Dashboard from "./pages/dashboard";
import Buildings from "./pages/buildings";
import CreateBuilding from "./pages/CreateBuildings";
import Alarm from "./pages/alarm";
import Filter from "./components/filter";
import Account from "./pages/account";
import CreateAlarm from "./pages/createAlarm";
import ViewDevices from "./pages/ViewDevices";
import ViewBuilding from "./pages/ViewBuilding";
import CreateDevice from "./pages/CreateDevice";
import EditDevice from "./pages/editDevice";
import EditAlarm from "./pages/editAlarm";
import SearchPage from "./pages/SearchPage";
import AllDevices from "./pages/devices/allDevices";
import Device from "./pages/devices/Device";

const  App = () => {
    
    const { isAuthenticated } = useKindeAuth();

    if(!isAuthenticated) {
        return(
            <Login />
        );
    }
    
    
    return(
        <GlobalStateProvider>
            <Navbar />
            <Filter />

            <div id="page">
                <Routes>

                    <Route exact path="/" element={<Dashboard />} />
                    <Route exact path="/users" element={<RecordList />} />
                    <Route exact path="/users/create" element={<Create />} />
                    <Route exact path="/users/edit/:id" element={<Edit />} />

                    <Route exact path="/buildings" element={<Buildings />} />
                    <Route exact path="/buildings/create" element={<CreateBuilding />} />
                    <Route exact path="/buildings/:buildingId/" element={<ViewDevices />} />
                    <Route exact path="/buildings/:buildingId/devices/" element={<ViewDevices />} />
                    <Route exact path="/buildings/:buildingId/edit/" element={<ViewBuilding />} />

                    <Route exact path="/buildings/:buildingId/devices/create" element={<CreateDevice />} />
                    <Route exact path="/buildings/:buildingId/devices/:deviceId/" element={<EditDevice />} />
                    <Route exact path="/buildings/:buildingId/devices/:deviceId/edit" element={<EditDevice />} />

                    <Route exact path="/devices" element={<AllDevices />} />
                    <Route exact path="/devices/:deviceId/" element={<Device />} />

                    <Route exact path="/alarms" element={<Alarm />} />
                    <Route exact path="/alarms/create" element={<CreateAlarm />} />
                    <Route exact path="/alarms/:alarmId/" element={<EditAlarm />} />
                    <Route exact path="/alarms/:alarmId/edit" element={<EditAlarm />} />

                    <Route exact path="/account" element={<Account />} />
                    <Route exact path="/sok" element={<SearchPage />} />
                    <Route path="/callback" element={<Callback />} />
                </Routes>

            </div>
        </GlobalStateProvider>
    )
};
 
export default App;