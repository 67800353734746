import React from "react";
import ReactDOM from "react-dom";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
ReactDOM.render(
  <KindeProvider 
    clientId="5d15dd8634544b909b38fc1f9d49a5e2"
    domain="https://insitemedia.kinde.com"
    logoutUri={`${process.env.REACT_APP_APP_URL}/`}
    redirectUri={`${process.env.REACT_APP_APP_URL}/`}
    onRedirectCallback={(user, app_state) => {
        console.log({user, app_state});
    }}
  >

  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
    
  </KindeProvider>
,
  document.getElementById("root")
);