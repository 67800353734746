import React, { useState, useEffect } from "react";
import Card from "../../components/card";
import axios from "axios";
import { Link } from "react-router-dom";

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useGlobalState } from "../../GlobalStateContext";

function AllDevices() {
    const { getToken } = useKindeAuth();
    const [devices, setDevices] = useState([]);

    const getDevices = async() =>{
        const accessToken = await getToken();
        axios.get(process.env.REACT_APP_API_URL+'/v1/devices', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(res => {
                const apiDevices = res.data;
                setDevices(apiDevices);
                console.log(res.data);
        });
    }

    useEffect(() => {
        getDevices();
    }, []);

    return(
        <div className="container-fluid space">
            <div className="row">
                <Card title="Alle enheter" variant="light" size="12">

                <div className="mobile-only">
                    {devices.map((device,i) => (
                        <div className="flex-information" key={`flex-device-${i}`}>
                            <div className="flex-box">
                                <strong>{device.name}</strong><br />
                                {device.sensorId}
                            </div>

                            <div className="flex-box">
                                {device.description}
                            </div>

                            <div className="flex-box flex-action">
                                <Link className="select-btn" to={"devices/" + device._id}>Velg</Link>
                            </div>
                        </div>
                    ))}
                </div>

                    <div className="desktop-only">
                        <table className="infotable">
                            <thead>
                                <tr>
                                    <th>Sensor</th>
                                    <th>Bygg</th>
                                    <th>Type</th>
                                    <th>Siste måling</th>
                                    <th>Handlinger</th>
                                </tr>
                            </thead>

                            <tbody>
                                {devices.map((device, i) => {
                                    let unitType = "";
                                    let typeText = "";
                                    if(device.measurementType === "temp") {
                                        unitType = device.measurementTypeUnit;
                                        typeText = "Sanntidsmåling";
                                    } else if(device.measurementType === "energy") {
                                        unitType = device.measurementTypeUnit;
                                        typeText = "Akkumulert";
                                    }else if (device.measurementType === "digital"){
                                        unitType = "0/1";
                                        typeText = "Digital"
                                    }
                                   

                                    return (
                                    <tr key={i}>
                                        <td>
                                            <strong>{device.name}</strong> <br />
                                            <span className="smaller">{device.sensorId}</span>
                                        </td>
                                        <td>
                                            <small style={{color:'gray'}}>{device?.building?.address}</small>
                                            <br/>
                                            <h5>{device?.building?.name}</h5>
                                        </td>
                                        <td>{typeText} ({unitType})</td>
                                        {device?.lrv ? 
                                        (
                                            <td>{device?.lrv} {device?.measurementType === "energy" ? '(Δ ' +device?.ldv + ')' :'' } ({unitType})</td>
                                        ):(
                                            <td></td>
                                        )}
                                    
                                        <td><Link className="select-btn" to={"/devices/" + device._id}>Velg</Link></td>
                                    </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    
                </Card>
            </div>
        </div>
    )
    
}

export default AllDevices;