import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Card from "../components/card";
import { Link } from "react-router-dom";
import axios from "axios";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../GlobalStateContext";
import EnergyPieChart from "../components/charts/EnergyPieChart";

function ViewDevices() {

    const navigate = useNavigate();
    const { getToken } = useKindeAuth();
    const [isDeleting, setIsDeleting] = useState(false);
    const [building, setBuilding] = useState({});
    const { buildingId } = useParams();
    const [energyData, setEnergyData] = useState([]);
    const { dateFilter } = useGlobalState();
    const [devicesEnergyData] = useState([])
    const getDevices = async() =>{
        const accessToken = await getToken();
        axios.get(`${process.env.REACT_APP_API_URL}/v1/buildings/${buildingId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(res => {
                const apiDevices = res.data;
                setBuilding(apiDevices);
        });
    }
    
    const getEnergyData = async (id) =>{
        if(id){
            const accessToken = await getToken();
            axios.get(process.env.REACT_APP_API_URL+`/v1/iotdata/building-energy-data/${id}?startDate=${dateFilter.startDate}&endDate=${dateFilter.endDate}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then(res => {
                const calculatedEnergyData = res?.data?.map((building,index)=> {
                    const categoryData = {
                        "Oppvarming":{
                            name:"Oppvarming",
                            value: Math.ceil(Math.random()*5000)
                            //value:0 This when out of development.TODO
                        },
                        "Varmtvann":{
                            name:"Varmtvann",
                            value: Math.ceil(Math.random()*5000)
                            //value:0 This when out of development.TODO
                        },
                        "Vifter og pumper": {
                            name:"Vifter og pumper",
                            value: Math.ceil(Math.random()*5000)
                            //value:0 This when out of development.TODO
                        },
                        "Belysning":{
                            name:"Belysning",
                            value: Math.ceil(Math.random()*5000)
                            //value:0 This when out of development.TODO
                        },
                        "Teknisk utstyr": {
                            name:"Teknisk utstyr",
                            value: Math.ceil(Math.random()*5000)
                            //value:0 This when out of development.TODO
                        },
                        "Kjøling":{
                            name:"Kjøling",
                            value: Math.ceil(Math.random()*5000)
                            //value:0 This when out of development.TODO
                        },
                        "Utendørs":{
                            name:"Utendørs",
                            value: Math.ceil(Math.random()*5000)
                            //value:0 This when out of development.TODO
                        },
                        "Ukategorisert":{
                            name:"Ukategorisert",
                            value: Math.ceil(Math.random()*5000)
                            //value:0 This when out of development.TODO
                        },
                    };
                    let returndata = building;
                    building?.devices?.map((device,idx) => {
                        let category = device.category;
                        const iotData = device.iotData;
                        if(!category){
                            category = "Ukategorisert"
                        }
                        
                        if(!categoryData?.[category]){
                            categoryData[category] = { name:category, value: 0, iotData:iotData ? iotData : []};
                        }
                    
                        if(iotData.length >0){
                            categoryData[category].value += Math.ceil(iotData.reduce((sum,curr) => sum + curr.avg_value,0) / iotData.length);
                        }
                        
                        
                    })
                    returndata.categories = categoryData;
                    returndata.totalEnergyUsage = Object.values(building.categories).reduce((sum, category) => sum + category.value, 0);
                    returndata.totalEnergyUsagePerBra = building.sizeBra ? returndata.totalEnergyUsage / building.sizeBra : 0;
                    returndata.totalEnergyUsagePerBra = returndata.totalEnergyUsagePerBra.toFixed(2)
                    return returndata
                });
                setEnergyData(calculatedEnergyData);
            });
        }
    return
    }
    useEffect(() => {
        getDevices();
        getEnergyData(buildingId);
    }, [buildingId]);


    const [selectedDevice, setSelectedDevice] = useState("");

    const handleDelete = (e) => {
        setIsDeleting(true);
    }

    const regretDelete = (e) => {
        setIsDeleting(false);
    }

    const handleBuildingDelete = async (e) => {
        e.preventDefault();
        const accessToken = await getToken();

        try {
            // Send a POST request with Axios
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/buildings/${buildingId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            console.log('Response:', response.data);
            
            // Construct the redirect URL
            const redirectUrl = `/buildings/`;
            console.log('Skal videresendes til:', redirectUrl);

            // Use the navigate function to redirect to the new URL
            navigate(redirectUrl);
        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
        }
    };
    return(
        <div className="container-fluid">
            <div className="row">
            <Card variant="light" title={building.name} size={"5"}>
                <div className="circle-company">
                    <div className="circle-icon">
                        <i className="fa-sharp fa-solid fa-building-memo"></i>
                    </div>
                    <h3>{building?.name}</h3>
                    <p>{building?.address}, {building?.city}</p>

                     <p style={{fontSize:'14px'}}>
                        {building?.floors ? `${building?.floors.length} etasje(r), totalt ${building?.floors?.reduce((sum,curr) => sum + (curr.rooms ? curr.rooms?.length:0),0)} rom` : '' }
                    </p>
                    <p style={{fontSize:'18px'}}>
                        BRA: {building.sizeBra} | BTA: {building.sizeBta}
                    </p>
                    
                    <p>
                    {energyData?.[0]?.totalEnergyUsagePerBra &&
                       <>     Energiforbruk: {energyData?.[0]?.totalEnergyUsagePerBra} kWh/m<sup>2</sup></>
                    }
                    </p>
                </div>
            </Card>
            <Card variant="light" title={building.name + " - Energiforbruk per kategori"} size={"7"}>
                
                <EnergyPieChart data={energyData} height={370} />
                    
                
            </Card>
            </div>
            <div className="row mt-3">
            <Card variant="light" title={building.name + " - Enheter"} size={selectedDevice !== "" ? "8" : "12"}>
                    <div className="alarmlist-wrapper">
                    <table className="infotable">
                    <thead>
                        <tr>
                        <th>Navn</th>
                        <th>Alarmer</th>
                        <th>Utløste Alarmer</th>
                        <th>Handlinger</th>
                        </tr>
                    </thead>
                    <tbody>
                            {building?.devicesAndAlarms?.map((device, i) => {

                                // Create a Date object from the timestamp
                                const createdAtDate = device.createdAt ? new Date(device.createdAt) : null;

                                // Check if createdAtDate is a valid date
                                const isValidDate = createdAtDate instanceof Date && !isNaN(createdAtDate);

                                // Format the date and time
                                const formattedDateTime = isValidDate
                                    ? `${(createdAtDate.getDate() + "").padStart(2, "0")}.${(createdAtDate.getMonth() + 1 + "").padStart(2, "0")}.${createdAtDate.getFullYear().toString().slice(-2)} ${createdAtDate.getHours()}:${(createdAtDate.getMinutes() + "").padStart(2, "0")}:${(createdAtDate.getSeconds() + "").padStart(2, "0")}`
                                    : "NaN";

                                return(
                                    <tr key={`device-row-${i}`}>
                                        <td> 
                                            
                                                <small style={{color:'gray'}}>{device.sensorId} ({device.deviceId})</small>
                                                <br/>
                                                <h5>{device.name}</h5>
                                            
                                        </td>
                                        <td><i className="fa-sharp fa-light fa-bell"></i> {device.alarms} </td>
                                        <td><i className="fa-sharp fa-light fa-bell-on"></i> {device.numTriggeredAlarms}</td>
                                        <td> 
                                            <div className="alarm-actions">
                                                <Link to={"/buildings/" + buildingId + "/devices/" + device._id + "/edit/"} className="select-btn secondary-action-btn">Rediger</Link>
                                                <Link to={"/devices/" + device._id} className="select-btn">Velg</Link>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        </table>
                        </div>

                    </Card>

                <div className="col-sm-12">
                    <button className="button action-delete" onClick={handleDelete}>Slett bygg</button>
                    <Link className="button create action-create" to={"/buildings/" + buildingId + "/devices/create"}>Opprett enhet</Link> 
                    <Link className="button action-edit" to={"/buildings/" + buildingId + "/edit"}>Rediger</Link>
                </div>
            </div> 

            {isDeleting ?
                <>
                    <div className="deleteModal">
                        <div className="deleteHeader">
                            <h2>Slett bygning?</h2>
                        </div>

                        <div className="deleteContent">
                            <p>Er du sikker på at du ønsker å slette bygg: "{building.name}"?</p>
                            <div className="buttonwrap">
                                <button onClick={regretDelete} className="button default">Avbryt</button>
                                <button onClick={handleBuildingDelete} className="button danger">Slett bygg</button>
                            </div>
                        </div>
                    </div>
                </>
            : ""}
        </div>
    );

}

export default ViewDevices;