import React, { useState, useEffect } from "react";
import Card from "../components/card";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useParams } from "react-router-dom";
import Select from 'react-select'
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  SubmitHandler,
  Controller
} from "react-hook-form";

const buildingInitialValues = {
    name: '',
    organization: '',
    address: '',
    city: '',
    zip: '',
    totalFloors: 0,
    sizeBra: '',
    sizeBta: '',
    devices: [],
    floors:[],
    rooms: [],
    miscEmployees: [],
}
function ViewBuilding() {
    const navigate = useNavigate();
    const { getToken } = useKindeAuth();
    const [isDeleting, setIsDeleting] = useState(false);
    const [building, setBuilding] = useState({});
    const [formData, setFormData] = useState({});
    const methods  = useForm();
    const { fields, append, remove } = useFieldArray({
        control:methods.control,
        name: "floors",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [buildingDevices, setBuildingDevices] = useState([]);
    const { buildingId } = useParams();
    const getBuildingDevices = async () => {
        try {
            const accessToken = await getToken();
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/buildings/${buildingId}/devices`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const buildingDevices = response.data;
            setBuildingDevices(buildingDevices);
            setIsLoading(false);
        } catch (error) {
            console.error('Error:', error);
        }
    } 
    const fetchData = async () => {
        try {
            const accessToken = await getToken();
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/buildings/${buildingId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            
            const apiBuilding = response.data;
            
            setBuilding(apiBuilding);
            // Set form values
            Object.entries(apiBuilding).forEach(([key, value]) => {
                if(key !== "floors"){
                    methods.setValue(key, value);
                }else{
                    value.forEach((floor,floorIndex)=> {
                        append({name:floor.name,rooms:floor.rooms})
                        /*methods.setValue(`floors.${floorIndex}.name`, floor.name);
                        floor.rooms.forEach((room, roomIndex) => {
                            methods.setValue(`floors.${floorIndex}.rooms.${roomIndex}.name`, room.name);
                            //console.log(room?.devices?.map(device => ({value:device?._id, label:device?.name})) || [])
                            methods.setValue(
                                `floors.${floorIndex}.rooms.${roomIndex}.devices`,
                                room?.devices?.map(device => (device)) || []
                            );
                            // If devices is an array, set it as an array
                            // methods.setValue(`floors[${floorIndex}].rooms[${roomIndex}].devices`, room.devices || []);
                        });*/
                    })
                }
            });
            setIsLoading(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(() => {
        fetchData();
        getBuildingDevices();
    }, [buildingId]);
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const accessToken = await getToken();
        const data = methods.getValues();
        try {
            // Send a PUT request with Axios
            
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/v1/buildings/${buildingId}`, data, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                console.log('Oppdatert - Response:', response.data);
                methods.reset()
                const redirectUrl = `/buildings/`;
                // Use the navigate function to redirect to the new URL
                navigate(redirectUrl);
            });

        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
        }
    }


    //const [rooms, setRooms] = useState(0);
    //console.log(building);
    
    
    const handleDelete = (e) => {
        setIsDeleting(true);
    }

    const regretDelete = (e) => {
        setIsDeleting(false);
    }

    const handleBuildingDelete = async (e) => {
        e.preventDefault();
        const accessToken = await getToken();

        try {
            // Send a POST request with Axios
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/buildings/${buildingId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            // Construct the redirect URL
            const redirectUrl = `/buildings/`;
            // Use the navigate function to redirect to the new URL
            navigate(redirectUrl);
        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
        }
    };


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <Card variant="light" title="Endre byggning" size="12"> 
                        {isLoading ? (
                            <div>Laster inn...</div>
                        ) : (
                            <FormProvider {...methods}>
                            <form className="create-building-form" id="createBuildingForm" onSubmit={handleFormSubmit}>
                                <div className="triple-form">
                                    <h3>Bygginformasjon</h3>

                                    <label htmlFor="name"><i className="fa-sharp fa-light fa-building"></i> Byggnavn / firmanavn</label>
                                    <input {...methods.register('name')} type="text" name="name"  className="form-control" />

                                    <input type="text" name="organization" hidden readonly  className="form-control" />

                                    <label htmlFor="address"><i className="fa-sharp fa-light fa-location-dot"></i> Adresse</label>
                                    <input {...methods.register('address')} type="text" name="address" className="form-control" />

                                    <div className="post-flex">
                                        <div className="post-flex-item">
                                            <label htmlFor="city"><i className="fa-sharp fa-light fa-location-dot"></i> Poststed</label>
                                            <input {...methods.register('city')} type="text" name="city"  className="form-control" />
                                        </div>

                                        <div className="post-flex-item">
                                            <label htmlFor="zip">Postnr.</label>
                                            <input {...methods.register('zip')} type="text" name="zip"  className="form-control" />
                                        </div>
                                    </div>
                                </div>
 

                                
                                <div className="triple-form">
                                    <label htmlFor="totalFloors"><i class="fa-sharp fa-light fa-buildings"></i> Etasjer</label>
                                    <input {...methods.register('totalFloors')} type="number" name="totalFloors" defaultValue={"1"} className="form-control" />

                                    <label htmlFor="sizeBra"><i className="fa-sharp fa-light fa-arrow-up-right-and-arrow-down-left-from-center"></i> Størrelse (BRA)</label>
                                    <input {...methods.register('sizeBra')} type="text" name="sizeBra"  placeholder="1600 m²" className="form-control" />

                                    <label htmlFor="sizeBta"><i className="fa-sharp fa-light fa-arrow-up-right-and-arrow-down-left-from-center"></i> Størrelse (BTA)</label>
                                    <input {...methods.register('sizeBta')} type="text" name="sizeBta"  placeholder="1400 m²" className="form-control" />
                                </div>

                                <div className="triple-form">
                                    <h3>Etasjer og rom</h3>
                                        {fields?.map((floor, floorIndex) => (
                                            <div key={floor.id} className="form-floor-wrapper">                                                
                                                <input {...methods.register(`floors.${floorIndex}.name`)} defaultValue={"Etasje "+floorIndex} placeholder="Romnavn" className="form-control" />
                                                <Rooms fieldIndex={floorIndex} devices={buildingDevices}></Rooms>
                                                <button type="button" className="small-text-remove" onClick={() => remove(floorIndex)}><i className="fa-thin fa-trash-can"></i>Slett etasje</button>
                                            </div>
                                        ))}
                                        
                                        
                                        <button onClick={() => append({ name: `${(fields.length+1)}. etasje `, rooms: [] })} className="small-text-add" type="button"> {/* Add type="button to prevent form submission */}
                                            <i className="fa-thin fa-regular fa-plus"></i> Legg til etasje
                                        </button>

                                </div>


                                </form>
                            </FormProvider>
                            )}
                        </Card>
                    </div>
                </div>

                    <div class="col-sm-12">
                        <button className="button action-delete" onClick={handleDelete}>Slett bygg</button>
                        <button type="submit" onClick={(e)=>{e.preventDefault();handleFormSubmit(e)}} className="button create">Oppdater</button>
                    </div>

                {isDeleting ?
                <>
                    <div className="deleteModal">
                        <div className="deleteHeader">
                            <h2>Slett bygning?</h2>
                        </div>

                        <div className="deleteContent">
                            <p>Er du sikker på at du ønsker å slette "{building.name}"?</p>
                            <div className="buttonwrap">
                                <button onClick={regretDelete} className="button default">Avbryt</button>
                                <button onClick={handleBuildingDelete} className="button danger">Slett bygg</button>
                            </div>
                        </div>
                    </div>
                </>
            : ""}
            </div>
    );

}
function Rooms({fieldIndex, devices}){
    const { register, control} = useFormContext();

    const {
        fields: rooms,
        append: appendRoom,
        remove: removeRoom
    } = useFieldArray({
        name: `floors.${fieldIndex}.rooms`
    });

    return (
        <fieldset>
            <i className="fa-sharp fa-regular fa-square-dashed"></i> Rom
            <div className="form-rooms-wrapper">
            {rooms.map((room, index) => (
                <section
                key={room.id}
                className="mb-2"
                > 
                <input
                type="text"
                {...register(`floors.${fieldIndex}.rooms.${index}.name`)}
                placeholder="Navn på rommet..."
                className="form-control mb-2"
                />
                <Controller
                    
                    control={control}
                    name={`floors.${fieldIndex}.rooms.${index}.devices`}
                    
                    render={({field:{onChange,value}}) => (
                        <Select placeholder="Velg sensor" className="room-device-select" 
                                onChange={(selectedOptions) => {
                                    const selectedDeviceIds = selectedOptions.map(option => option.value);
                                    onChange(selectedDeviceIds || []); // Set the selected device ids
                                }}
                                
                            defaultValue={devices?.map((device) => {
                                //console.log(getValues());
                                if(value?.includes(device._id)){
                                    return {value:device._id, label: device.name}
                                }
                            }
                            )}
                            options={devices?.map((device) => (
                                {value:device._id, label: device.name}
                            ))} 
                            isMulti
                            hideSelectedOptions
                            >
                        </Select>
                    )}
                />
        
                <button
                    onClick={() =>  removeRoom(index)}
                    className="small-text-remove"
                    type="button" // Add type="button to prevent form submission
                >
                    <i className="fa-thin fa-trash-can"></i> Fjern rom  
                </button>
                </section>
            ))}
            </div>
            <button type="button"  className="small-text-add" onClick={() => appendRoom({ name: "", devices:[] })}>
            <i className="fa-thin fa-regular fa-plus"></i> Legg til rom
            </button>
        </fieldset>
    );
}

export default ViewBuilding;