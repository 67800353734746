import React from 'react'
import { Link } from 'react-router-dom'

export default function SearchResults({results , empty, query}) {
  if(results=== undefined|| empty === true){
    return (
    <div className="search-results">
      <div className="result-line">
        Ingen Resultater
      </div>
    </div>
    )
  }
  const renderHighlight = (highlight) => {
    if (!highlight || !highlight.texts) {
        return null; // Return null if highlight or texts array is not present
    }

    const combinedText = highlight.texts.map((text, index) => {
        if (text.type === "hit") {
            return <span key={index} className="highlight">{text.value}</span>;
        } else {
            return <span key={index}>{text.value}</span>;
        }
    });

    return combinedText;
  }
  return (
    <div className="search-results">
      { results?.devices?.length >0 && (
        <>
        <div className='result-header'>
          <div className="result-title my-auto row">
            <div className="col-6 my-auto">
            <h5>
            <i className="fa-sharp fa-light  me-2 fa-sensor"></i>
            Enheter
            </h5>
            </div>
            <div className="col-6 text-end search-result-count">
              {results.devices.length} resultater
            </div>
          </div>
          
        </div>
        {results?.devices?.slice(0,3).map((device,index)=> {
          return(
              <Link to={`/devices/${device._id}`}  className="result-line-link" key={`device-search-${index}`}>
            <div className='result-line' >
              <div className='row'>
                <div className='col-sm-6 result-line-name'>
                  {device.name}
                </div>
                <div className='col-sm-6 highlight-wrapper'>
                  {device?.highlights.map((highlight,idx) => (
                    <div className='highlight-row text-end' key={`${device._id}-hl-${idx}`}>
                      '{highlight.path}' : {renderHighlight(highlight)}
                    </div>
                  ))}
                </div>
              </div>
             </div>    
              </Link>
          )
        })}
        {results?.devices?.length >=3 && (
            <Link to={`/sok?model=devices&s=${query}`}  className="result-line-link">
            <div className='result-show-more'> 
              Vis flere resultater ({results?.devices?.length})
             </div>    
            </Link>
        )}
        </>
      )}
      { results?.alarms?.length >0 && (
        <>
        <div className='result-header'>
          <div className="result-title my-auto row">
            <div className="col-6 my-auto">
            <h5>
            <i className="fa-sharp fa-light  me-2 fa-bell-on"></i>
              Alarmer
            </h5>
            </div>
            <div className="col-6 text-end search-result-count">
              {results.alarms.length} resultater
            </div>
          </div>
          
        </div>
        {results?.alarms?.slice(0,3).map((alarm,index)=> {
          return(
            <Link to={`/alarms/${alarm._id}`} className="result-line-link" key={`alarm-search-${index}`}>
            <div className='result-line' >
              <div className='row'>
                <div className='col-sm-6 result-line-name'>
                  {alarm.name}
                </div>
                <div className='col-sm-6 highlight-wrapper'>
                  {alarm?.highlights.map((highlight,idx) => (
                    <div className='highlight-row text-end' key={`${alarm._id}-hl-${idx}`}>
                      '{highlight.path}' : {renderHighlight(highlight)}
                    </div>
                  ))}
                </div>
              </div>
             </div>    
              </Link>
          )
        })}
        {results?.alarms?.length >0 && (
            <Link to={`/sok?model=alarms&s=${query}`}  className="result-line-link">
            <div className='result-show-more'> 
              Vis flere resultater ({results?.alarms?.length})
             </div>    
            </Link>
        )}
        </>
      )}
      { results?.buildings?.length >0 && (
        <>
        <div className='result-header'>
          <div className="result-title my-auto row">
            <div className="col-6 my-auto">
            <h5>
            <i className="fa-sharp fa-light  me-2 fa-buildings"></i>
              Bygg
            </h5>
            </div>
            <div className="col-6 text-end search-result-count">
              {results.buildings.length} resultater
            </div>
          </div>
          
        </div>
        {results?.buildings?.slice(0,3).map((building,index)=> {
          return(
            <Link to={`/buildings/${building._id}`}  className="result-line-link" key={`building-search-${index}`}>
            <div className='result-line' >
              <div className='row'>
                <div className='col-sm-6 result-line-name'>
                  {building.name}  {building.address !== undefined ? (<><br/><span style={{fontSize:'12px'}}>{building.address}</span> </>): '' }
                </div>
                <div className='col-sm-6 highlight-wrapper'>
                  {building?.highlights.map((highlight,idx) => (
                    <div className='highlight-row text-end' key={`${building._id}-hl-${idx}`}>
                      '{highlight.path}' : {renderHighlight(highlight)}
                    </div>
                  ))}
                </div>            
              </div>
             </div>    
            </Link>
          )
        })}
        {results?.buildings?.length >2 && (
            <Link to={`/sok?model=buildings&s=${query}`}  className="result-line-link">
            <div className='result-show-more'> 
              Vis flere resultater ({results?.buildings?.length})
             </div>    
            </Link>
        )}
        </>
      )}
      
    </div>
  )
}
