import React from "react";

/*
* Row Component
* Accepts props: mTop and mBot
*
*   - mTop = Margin Top
*   - mBot = Margin Bottom
*/

function Row({children, ...props}) {
    return(
        <div className={"row"} style={{marginTop: props.mTop, marginBottom: props.mBot}}>
            {children}
        </div>
    )
}

export default Row;