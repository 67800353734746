import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "./breadcrumbs";
import { useGlobalState } from "../GlobalStateContext";

import MultiCalendar from "./MultipleDayCalendar";

function Filter() {

    // Import moment library for easier date formating and calculations
    const moment = require('moment');
    const today = new Date();
    var formatToday = moment(today).format('YYYY-MM-DD');


    // Define all predefined custom date-ranges and format them

    // Yesterday
    var yesterday = moment(today).subtract(1, 'days');
    var formatYesterday = moment(yesterday).format('YYYY-MM-DD');

    // This week
    var thisWeekStart = moment(today).startOf('isoWeek');
    var thisWeekEnd = moment(today).endOf('isoWeek');
    var formatThisWeek = moment(thisWeekStart).format('YYYY-MM-DD');
    var formatthisWeekEnd = moment(thisWeekEnd).format('YYYY-MM-DD');

    // Last week
    var lastWeekStart = moment().subtract(1, 'week').startOf('isoWeek');
    var lastWeekEnd = moment().subtract(1, 'week').endOf('isoWeek');
    var formatLastWeekEnd = moment(lastWeekEnd).format('YYYY-MM-DD');
    var formatLastWeekStart = moment(lastWeekStart).format('YYYY-MM-DD');


    // This month
    var thisMonth = moment(today).month();
    var thisMonthStart = moment(today).startOf('month');
    var thisMonthEnd = moment(today).endOf('month');
    var formatThisMonthStart = moment(thisMonthStart).format('YYYY-MM-DD');
    var formatThisMonthEnd = moment(thisMonthEnd).format('YYYY-MM-DD');

    // Last month
    var lastMonthStart = moment().subtract(1, 'month').startOf('month');
    var lastMonthEnd = moment().subtract(1, 'month').endOf('month');
    var formatLastMonthStart = moment(lastMonthStart).format('YYYY-MM-DD');
    var formatLastMonthEnd = moment(lastMonthEnd).format('YYYY-MM-DD');

    // Last 30 days
    var lastThirtyDays = moment(today).subtract(30, 'days');
    var formatLastThirtyDays = moment(lastThirtyDays).format('YYYY-MM-DD');

    // Year
    var thisYear = moment(today).year();

    const { dateFilter, updateDateFilter } = useGlobalState();

    // useState for state management, needs to be passed as props to entire application
    const [startDate, setStartDate] = useState(dateFilter.startDate);
    const [endDate, setEndDate] = useState(dateFilter.endDate);

    const [selectingStartDate, setSelectingStartDate] = useState(true);
    const [selectingEndDate, setSelectingEndDate] = useState(false);

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);

    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    useEffect(() => {

        if(dateFilter.endDate === dateFilter.startDate) {
            //console.log("test")
            setSelectingEndDate(true);
            setSelectingStartDate(false);
            return;
        }

        if(selectingEndDate === true) {
            setSelectingEndDate(false);
            setSelectingStartDate(true);
        } else {
            setSelectingEndDate(true);
            setSelectingStartDate(false);
        }
    }, [dateFilter.startDate, dateFilter.endDate])
    

    useEffect(()=>{
        updateDateFilter(startDate,endDate);
    },[startDate,endDate]);

    // Check if time range is clicked
    const [dateRange, setDateRange] = useState(0);

    const formatSelectedDate = (date) => {return moment(date).format('DD.MM.YYYY')};
    //const formatSelectedEnd  = () =>  {moment(endDate).format('DD.MM.YYYY')};


    return(
        <div className="date-filter">
            {dateRange == 1 ? <div id="overlay" onClick={() => setDateRange(0)}></div> : ""}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 filter-col">
                        
                        <div id="breadcrumbs">
                            <BreadCrumbs />
                        </div>
                        <div id="custom-date-filter">
                            <button className="time-select" onClick={() => setDateRange(1)}>
                                <i className="fa-sharp fa-light fa-calendar"></i> 
                                
                                    {dateFilter.startDate == dateFilter.endDate ? formatSelectedDate(dateFilter.startDate) : formatSelectedDate(dateFilter.startDate) + " - " +  formatSelectedDate(dateFilter.endDate)}
                                <i className="fa-sharp fa-regular fa-chevron-down"></i>
                            </button>
                            
                            {dateRange === 1 ?
                                <div id="custom-date-pop">
                                    <div className="top-area">
                                        <input
                                            className={selectingStartDate === true ? "time-select selecting" : "time-select"}
                                            type="date"
                                            id="startDate"
                                            value={dateFilter.startDate}
                                            onChange={handleStartDateChange}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSelectingEndDate(false);
                                                setSelectingStartDate(true);
                                              }}
                                        />

                                        <span>til</span>

                                        <input
                                            className={selectingEndDate === true ? "time-select selecting" : "time-select"}
                                            type="date"
                                            id="endDate"
                                            value={dateFilter.endDate}
                                            onChange={handleEndDateChange}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSelectingEndDate(true);
                                                setSelectingStartDate(false);
                                              }}
                                        />

                                    </div>
                                    
                                    <div className="left-area" id="slide-wrapper">
                                        <MultiCalendar selectingStart={selectingStartDate} selectingEndDate={selectingEndDate} month={thisMonth + 1} year={thisYear}/>
                                    </div>

                                    <div className="right-area">
                                        <ul className="date-ranges">
                                            <li><button onClick={() => setStartDate(formatToday) - setEndDate(formatToday)}>I dag</button></li>
                                            <li><button onClick={() => setStartDate(formatYesterday) - setEndDate(formatYesterday)}>I går</button></li>
                                            <li><button onClick={() => setStartDate(formatThisWeek) - setEndDate(formatthisWeekEnd)}>Denne uken</button></li>
                                            <li><button onClick={() => setStartDate(formatLastWeekStart) - setEndDate(formatLastWeekEnd)}>Forrige uke</button></li>
                                            <li><button onClick={() => setStartDate(formatThisMonthStart) - setEndDate(formatThisMonthEnd)}>Denne måned</button></li>
                                            <li><button onClick={() => setStartDate(formatLastMonthStart) - setEndDate(formatLastMonthEnd)}>Forrige måned</button></li>
                                            <li><button onClick={() => setStartDate(formatLastThirtyDays) - setEndDate(formatToday)}>Siste 30 dager</button></li>
                                        </ul>

                                    </div>
                                </div>
                                : "" }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter;