import React from "react";

/*
* Card Component
* Accepts props: type, size and title
*
*   - size: 12 column system, int: 1-12 (Bootstrap)
*   - variant: dark / light
*   - title: Title on Card - Varchar.
*/

function Card({children,title, ...props}) {
    return(
        <div className={"col-sm-" + props.size + " c-bg-" + props.variant}>
            <div className={"card"} {...props}>
            {title ? 
                <div className="c-card--header">
                        <h2>{title}</h2>
                </div>
            : ""}

                <div className="c-card-content">                    
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Card;