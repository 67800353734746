import React, { useState, useEffect } from "react";
import Card from "../components/card";
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useNavigate } from "react-router-dom";

function CreateDevice() {

    const navigate = useNavigate();
    const { getToken } = useKindeAuth();
    //const [building, setBuilding] = useState({});
    const [building, setBuilding] = useState({});
    const [formData, setFormData] = useState({});
    const [selectedFloor, setSelectedFloor] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const { buildingId } = useParams();

    useEffect(() => {
        const getDevices = async () => {
            try {
                const accessToken = await getToken();
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/buildings/${buildingId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const building = response.data;
                setBuilding(building);
                setFormData({
                    name: '',
                    organization: building.organization || '',
                    building: building._id,
                    deviceId: '',
                    topic: '',
                    sensorId: '',
                    measurementType: '',
                    measurementTypeUnit: '℃',
                    description: '',
                    category:'',
                    siteId: '',
                    realEstate: '',
                    floor_rom:'',
                    floor: '',
                    servesRoom: '',
                    placementRoom: '',
                    // Add more fields as needed
                });
                setIsLoading(false);
            } catch (error) {
                console.error('Error:', error);
            }
        }

        getDevices();
    }, [buildingId, getToken]);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const accessToken = await getToken();
        console.log(formData);
        try {
            // Send a POST request with Axios
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/devices/`, formData,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

            // Handle the response here
            console.log('Response:', response.data);

            // Get the _id from the response
            const buildingId = building._id;

            // Construct the redirect URL
            const redirectUrl = `/buildings/${buildingId}/devices/`;
            console.log('Skal videresendes til:', redirectUrl);

            // Use the navigate function to redirect to the new URL
            navigate(redirectUrl);
        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
        }
    };
    const handleEnergyCategoryChange = (e) => {
        const selectedType = e.target.value;
        
    }
    const handleInputChange = (e) => {
        // Update form data state as the user types.
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
        console.log(formData);
    };
    const handleSensorTypeChange = (event) => {
        const selectedType = event.target.value;
        let unit = "";
        let category = formData.category;
        if (selectedType === "temp") {
            unit = "℃";
        }else if (selectedType === "energy") {
            unit = "kWh";
        }else if (selectedType === "digital"){
            unit = ""; // Set default unit for other sensor types
        }
        else {
            unit = ""; // Set default unit for other sensor types
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            measurementType: selectedType,
            measurementTypeUnit: unit
        }));
    };

    return(
        <div className="container-fluid">
            <div className="row">
                <Card variant="light" size="12" title="Opprett enhet">
                    <form className="create-building-form" id="createBuildingForm">
                        <div className="triple-form">
                            <h3>Enhetsinformasjon</h3>
                            <input type="text" hidden readonly value={buildingId} />
                            <input type="text" hidden readonly value={building.organization} name="organization" />

                            <label for="name"><i className="fa-sharp fa-light fa-database"></i> Enhetsnavn</label>
                            <input onChange={handleInputChange} type="text" name="name" placeholder="Enhetsnavn" className="form-control" />

                            <label for="sensorId"><i className="fa-sharp fa-light fa-database"></i> Sensor ID *</label>
                            <input onChange={handleInputChange} type="text" name="sensorId" placeholder="#323232" className="form-control" />

                            {/***
                             * DEVIDE ID IS CHANGED TO SENSOR ID AS PER TOPIC
                             * 
                             * <label for="deviceId"><i className="fa-sharp fa-light fa-database"></i> Enhets ID</label>
                             * <input onChange={handleInputChange} type="text" name="deviceId" placeholder="#23232" className="form-control" />
                             * 
                             */}
                            

                            <label for="description"><i className="fa-sharp fa-light fa-database"></i> Beskrivelse</label>
                            <textarea placeholder="Skriv en beskrivelse her..." onChange={handleInputChange} name="description" className="form-control">
                                
                            </textarea>
                        </div>

                        <div className="triple-form">
                            <h3>API / databroker</h3>
                            
                            <label htmlFor="topic">Topic</label>
                            <input onChange={handleInputChange} value={formData.topic} type="text" name="topic" placeholder="topic/eksempel/kontor/sensorId" className="form-control" />
                            
                            <label htmlFor="sensorType">Sensortype</label>
                            <select onChange={handleSensorTypeChange} value={formData.measurementType} name="measurementType" className="form-control">
                                <option value="temp">Sanntidsmåling</option>
                                <option value="energy">Akkumulert</option> {/* Add other sensor types as needed */}
                                <option value="digital">Digital (1/0)</option> {/* Add other sensor types as needed */}
                            </select>

                            <label htmlFor="sensorTypeUnit">Sensortype enhet</label>
                            <input value={formData.measurementTypeUnit} type="text" name="measurementTypeUnit" className="form-control"/>
                            {formData.measurementType === "energy" && (
                                <>
                                <label>Energimåling kategori</label>
                                <select onChange={handleInputChange}  name="category" className="form-control">
                                    <option hidden value="">Velg Kategori...</option>
                                    <option value="">Ingen kategori</option>
                                    <option value="Oppvarming">Oppvarming</option>
                                    <option value="Varmtvann">Varmtvann</option>
                                    <option value="Vifter og pumper">Vifter og pumper</option>
                                    <option value="Belysning">Belysning</option>
                                    <option value="Teknisk utstyr">Teknisk utstyr</option>
                                    <option value="Kjøling">Kjøling</option>
                                    <option value="Utendørs">Utendørs</option>
                                </select>
                                </>
                            )}

                        </div>
                        

                        <div className="triple-form">
                            <h3>Annet</h3>
                            
                            
                            <h6 style={{marginTop:'35px'}}>{building.name}</h6>

                            <label for="floor"><i className="fa-sharp fa-light fa-buildings"></i> Etasje og rom</label>
                            <select onChange={handleInputChange} name="floor_room" className="form-select">
                                <option key={`null_option_`} value={""}>
                                    Velg etasje og rom
                                </option>
                                {building?.floors?.map((floor,floor_index) =>{
                                    return (
                                        <React.Fragment key={`floor_${floor_index}`}>
                                            {floor?.rooms?.map((room, room_index) => (
                                                <option key={`option_${room_index}`} value={`${floor_index}-${room_index}`}>
                                                    {floor.name + " - " + room.name}
                                                </option>
                                            ))}
                                        </React.Fragment>
                                    );
                                })
                                }
                            </select>
                        </div>

                    </form>
                </Card>

                <div className="col-sm-12">
                    <button type="submit" onClick={handleFormSubmit} className="button create"><i className="fa-sharp fa-regular fa-plus"></i> Opprett enhet</button>
                </div>
            </div>
        </div>

    );
}

export default CreateDevice;