import React, { useState, useEffect } from "react";
import Card from "../components/card";
import { Link } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import Chart from "react-apexcharts";
import { useGlobalState } from "../GlobalStateContext";
import moment from "moment";
import 'moment/locale/nb';
import { Modal } from "react-bootstrap";
import Select from 'react-select'
import { Controller, useForm } from "react-hook-form";
import CreatableSelect from 'react-select/creatable';
import chroma from 'chroma-js';
import { severityColors } from "../components/severityColors";
const alarmStatusOptions = [
    { value: 'danger', label: 'Feil', color: '#FF5630'},
    { value: 'warning', label: 'Advarsel', color: '#FF8B00' },
    { value: 'info', label: 'Info', color: '#00B8D9'},
    { value: 'success', label: 'Ok', color: '#36B37E' }
];

function Alarm() {


    const { getToken } = useKindeAuth();
    const [alarms, setAlarms] = useState([]);

    const [selectedAlarm, setSelectedAlarm] = useState(null);
    const [selectedHistoryAlarm, setSelectedHistoryAlarm] = useState(null);
    const [triggeredAlarms, setTriggeredAlarms] = useState([]);
    const { aquaflowUser, dateFilter, updateDateFilter, setStartDate, setEndDate, refreshTriggeredAlarms, triggeredAlarmsCount } = useGlobalState();
    const [contractors, setContractors] = useState(aquaflowUser?.user?.organizations.map((org)=>{return ((org.contractors ? org.contractors : []))}).flat());

    const [selectedTriggeredAlarm, setSelectedTriggeredAlarm] = useState(null);
    const [showTriggeredAlarm, setShowTriggeredAlarm] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { control, register, setValue, getValues, handleSubmit, reset} = useForm({

        severity: alarmStatusOptions[1], // Default to 'Advarsel'
        comment: '',
    });
    
    const [triggeredAlarmSensorData , setTriggeredAlarmSensorData] = useState(
        {
            options:{ 
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: 'datetime',
                },
            },
            sensordata:undefined
        });
    const handleClose = () => setShowTriggeredAlarm(false);
    const handleShow = () => {
        setShowTriggeredAlarm(true)
        setTriggeredAlarmSensorData({
            options:{ 
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: 'datetime',
                },
            },
            sensordata:undefined
        });
    };
    useEffect(()=>{
        if(triggeredAlarmSensorData.sensordata !== undefined){
            setIsLoading(false);
        }
    },[triggeredAlarmSensorData])

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;

    const totalPages = Math.ceil((selectedAlarm?.alarmHistory?.length || 0) / recordsPerPage);
    const dot = (color = 'transparent') => ({
        alignItems: 'center',
        display: 'flex',
      
        ':before': {
          backgroundColor: color,
          borderRadius: 10,
          content: '" "',
          display: 'block',
          marginRight: 8,
          height: 10,
          width: 10,
        },
      });
   
    
        const colourStyles = {
            control: (styles) => ({ ...styles, backgroundColor: 'white' }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? data.color
                : isFocused
                ? color.alpha(0.1).css()
                : undefined,
                color: isDisabled
                ? '#ccc'
                : isSelected
                ? chroma.contrast(color, 'white') > 2
                    ? 'white'
                    : 'black'
                : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',
        
                ':active': {
                ...styles[':active'],
               
                },
            };
            },
            input: (styles) => ({ ...styles, ...dot() }),
            placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
            singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
            /* 
              singleValue: (styles, { data }) => ({
                ...styles,
                display: 'flex',
                alignItems: 'center',
                ':before': {
                backgroundColor: data.color,
                borderRadius: 10,
                content: '" "',
                display: 'block',
                marginRight: 0, // Adjust this to change the space between the dot and the hidden text
                height: 10,
                width: 10
                },
                // Hide the label
                color: 'transparent',
                textIndent: '-9999px'
            }),
            */
        };

    const getCurrentPageData = () => {
        const startIndex = (currentPage - 1) * recordsPerPage;
        const endIndex = Math.min(startIndex + recordsPerPage, selectedAlarm?.alarmHistory?.length);
        return selectedAlarm?.alarmHistory?.slice(startIndex, endIndex);
    }

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
            <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`page-link ${currentPage === i ? 'active' : ''}`}
            >
            {i}
            </button>
        );
        }
        return pageNumbers;
    };


    const [alarmHistorySensorData , setAlarmHistorySensorData] = useState(
        {
            options:{ 
                dataLabels: {
                    enabled: false
                },
                chart: {
                    id: "basic-bar",
                    type:"line"
                },
                xaxis: {
                    type: 'datetime',
                    min: new Date(dateFilter.startDate)  ,
                    max: new Date(dateFilter.endDate) ,
                    
                },
            },
            sensordata:undefined
        });
    
 
    //console.log(aquaflowUser);
    const getAlarms = async () => {
        const accessToken = await getToken();
        axios
            .get(`${process.env.REACT_APP_API_URL}/v1/alarms/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((res) => {
                const apiAlarms = res.data;
                setAlarms(apiAlarms);
            });
    }
    const getTriggeredAlarms = async () => {
        const accessToken = await getToken();
        axios
            .get(`${process.env.REACT_APP_API_URL}/v1/alarms?triggered=true`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((res) => {
                //console.log(res.data);
                setTriggeredAlarms(res.data);
            });
    }

    useEffect(() => {
        getAlarms();
        getTriggeredAlarms();
    }, []);
    useEffect(()=> {
        getTriggeredAlarms();
    },[triggeredAlarmsCount]);

    const handleSelectTriggeredAlarm = async(e,alarm) => {
        const accessToken = await getToken();
        axios
        .get(`${process.env.REACT_APP_API_URL}/v1/alarms/${e.target.value}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        .then((res) => {
            setSelectedTriggeredAlarm(res.data);
            getTriggeredAlarmSensorData(res.data)
            handleShow();
        })
    }
    const getTriggeredAlarmSensorData = async(alarm) => {
        const accessToken = await getToken();
        let startpoint = new Date(alarm.triggeredAt);
        let endpoint = new Date();
        // Extend the time range by one hour before and after the alarm times
        startpoint.setHours(startpoint.getHours() - 1);
        endpoint.setHours(endpoint.getHours() + 1);
        startpoint = startpoint.toISOString();
        endpoint = endpoint.toISOString();
        //console.log([startpoint,endpoint]);
        axios.get(`${process.env.REACT_APP_API_URL}/v1/alarms/${alarm._id}/iotdata?startDate=${startpoint}&endDate=${endpoint}&live=1`,{ 
            headers: {
              Authorization: `Bearer ${accessToken}`,
              },
            }).then((res)=>{
                if(res?.data?.AlarmWithIotData !== undefined){
                    if(res.data?.AlarmWithIotData[0] !== undefined ){
                        let data = res.data.AlarmWithIotData
                        const categories = data.map(item => item.timestamp);
                        let timeseries = []
                        if(alarm.alarmType !== "energy"){
                            timeseries = data.map(item => [new Date(item.timestamp),item.avg_value]);                        
                        }else{
                            timeseries = data.map((item,index) => {
                                //console.log(item.avg_value - (data?.[index-1] ? data?.[index-1].avg_value: 0));
                                if(index ===0) {
                                    return [new Date(item.timestamp), 0];
                                }
                                return [new Date(item.timestamp),item.avg_value - (data?.[index-1] ? data?.[index-1].avg_value: 0)];                    
                            })
                        }
                        let limitMin = alarm?.tempLimits?.min !== null ? alarm?.tempLimits?.min : null
                        let limitMax = alarm?.tempLimits?.max !== null ? alarm?.tempLimits?.max : null
                        let yaxisAnnotations = [];
                       
                        let fallbackYAxis = timeseries?.[0]?.[1] ? Math.floor(timeseries[0][1]):0;
                        if(alarm.alarmType === "energy"){
                            fallbackYAxis = Math.min(...timeseries.map((item)=> item[1]));
                        }
                        const deadband = alarm.deadband
                        let yaxisLimits = {
                            type:'integer',
                            min: (limitMin ? limitMin  - 10 : fallbackYAxis - 10),
                            max: (limitMax ? limitMax + 10 : fallbackYAxis + 10) ,
                            labels: {
                              formatter: function(val) {
                                return val.toFixed(1);
                              }
                            },
                            title:{
                              text: "Temperatur i Celsius (℃)",
                            },
                        };
                        const statusHistory = alarm?.currentStatusHistory?.map((status, index)=>{
                            console.log(status)
                            const statusColor = severityColors?.[status.severity] || "#FF4560"; // Default color if status not found
                            let statusText = ""
                            if(status?.status){
                                statusText = status.status.charAt(0).toUpperCase() + status.status.slice(1); // Capitalize first letter
                            }
                            if(status.status !=="resolved"){
                                const annotationx = {
                                    x: new Date(status.updatedAt).getTime(),
                                    borderColor: statusColor,
                                    strokeDashArray: 0,
                                    label: {
                                      borderColor: statusColor,
                                      style: {
                                        color: "#fff",
                                        background: statusColor
                                      },
                                      orientation: "horizontal",
                                      text: statusText
                                    }
                                  };
                                return annotationx
                            }
                        })
                        if(alarm?.alarmType === "digital"){
                            yaxisLimits = {
                                type:'integer',
                                min: -1,
                                max: 2 ,
                                labels: {
                                  formatter: function(val) {
                                    return val.toFixed(1);
                                  }
                                },
                                title:{
                                  text: "Alarm",
                                },
                            };
                            yaxisAnnotations = [
                                {
                                    y: 0.5,
                                    y2: 1,
                                    
                                    fillColor: '#FF0000',
                                    label: {
                                      text: `1 = Alarm`
                                    }
                                  },
                                  
                                  {
                                    y: 0,
                                    y2: 0.5,
                                    
                                    fillColor: '#68cc6b',
                                    
                                  },
                                  {y:0,
                                    label: {
                                        text: "0 = Normal",
                                       
                                      }
                                  }
                              ]
                        }else{
                            yaxisAnnotations = [
                                {
                                    y: limitMin ? limitMin : -500,
                                    y2: limitMax ? limitMax : 500,
                                    
                                    fillColor: '#68cc6b',
                                    label: {
                                      text: `Grense [${limitMin ? limitMin : '<-'},${limitMax ? limitMax : '->'} ]`
                                    }
                                  },
                                  
                                  {
                                    y: limitMin - deadband,
                                    y2:limitMin + deadband,
                                    
                                    fillColor: '#eed202',
                                
                                    label: {
                                      text: "Deadband +/-"+ ( deadband),
                                    }
                                  },
                                  {
                                    y: limitMax + deadband,
                                    y2: limitMax - deadband,
                                    
                                    fillColor: '#eed202',
                                    
                                   
                                  }
                              ]
                        }
                        
                        setTriggeredAlarmSensorData({
                            sensordata:[{
                                deviceId    :   alarm.deviceId,
                                name        :   alarm?.name,
                                data : timeseries,
                                
                            }],
                            
                            options:{
                                
                                dataLabels: {
                                  enabled: false
                                },
                                chart: {
                                  id: "triggered-alarm",
                                  type:"line"
                                },
                                xaxis: {
                                    type: 'datetime',
                                    tickPlacement: 'between',
                                    labels: {
                                        datetimeUTC: false
                                    },
                                    
                                    min: (new Date(startpoint).getTime()) - (((alarm?.delayBeforeTriggeredInMinutes? alarm.delayBeforeTriggeredInMinutes : 0) +1) * 1000 *60 *60)  ,
                                    max: (new Date(endpoint).getTime()) + (((alarm?.delayBeforeTriggeredInMinutes? alarm.delayBeforeTriggeredInMinutes : 0) +1) * 1000 *60 *60) ,
                                    
                                },
                                fill: {
                                    type: 'solid'
                                },
                                stroke: {
                                    show: true,
                                    curve: alarm.alarmType !== "digital" ? "smooth": 'stepline',
                                    lineCap: 'butt',
                                    colors: undefined,
                                    width: 2,
                                    dashArray: 0, 
                                },

                                yaxis:yaxisLimits,
                                
                                tooltip:{
                                    y:{
                                        formatter: function(val){
                                            if(alarm.alarmType === "digital")return `${val}`;
                                            return `${val.toFixed(2)}${alarm?.device?.measurementTypeUnit}`
                                        }
                                    },
                                    x: {
                                        format: "dd.MM.yyyy - HH:mm"
                                    }
                                
                                },
                                
                                annotations: {
                                    xaxis : [
                                        {
                                            x: new Date(alarm?.delayTriggeredAt).getTime(),
                                            borderColor: "#FF4560",
                                            strokeDashArray: 1,
                                            
                                        },
                                        {
                                            x: new Date(alarm?.triggeredAt).getTime(),
                                            borderColor: "#FF4560",
                                            strokeDashArray: 0,
                                            label: {
                                                borderColor: "#FF4560",
                                                style: {
                                                    color: "#fff",
                                                    background: "#FF4560"
                                                  },
                                                orientation: "horizontal",
                                                text: `Utløst`
                                              }
                                        },
                                        ...statusHistory
                                     
                                    ],
                                    yaxis: yaxisAnnotations,
                                    
                                  }
                              }
                        
                            
                        });
                    }
                }
            }).catch((error)=>{
                console.log(error)
            })
    }
    const handleDeSelectTriggeredAlarm = async(e) => {
        setSelectedTriggeredAlarm(null);
    }
    const handleSelect = async (e) => {
        const accessToken = await getToken();
        axios
        .get(`${process.env.REACT_APP_API_URL}/v1/alarms/${e.target.value}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        .then((res) => {
            
            setSelectedAlarm(res.data);
        })
       
    }
    
   
    const handleDeselect = (e) => {
        setSelectedAlarm(null);
        setSelectedHistoryAlarm(null);
    }
    const handleClickAddRules = (e) => {
        e.preventDefault();
        
    }
    const handleSelectHistory = (e) => {
        setSelectedHistoryAlarm(e.target.value);
    }

    const handleDeselectHistory = () => {
        setSelectedHistoryAlarm(null);
    }

    const fetchAlarmHistoryIotData = async (index)=>{
        if(selectedAlarm._id !== undefined && index >=0 && selectedHistoryAlarm){
            const accessToken = await getToken();
            let startpoint = new Date(selectedAlarm.alarmHistory[selectedHistoryAlarm].delayTriggeredAt || selectedAlarm.alarmHistory[selectedHistoryAlarm].triggeredAt);
            let endpoint = new Date(selectedAlarm.alarmHistory[selectedHistoryAlarm].resolvedAt);

            // Extend the time range by one hour before and after the alarm times
            startpoint.setHours(startpoint.getHours() - 1);
            endpoint.setHours(endpoint.getHours() + 1);
            startpoint = startpoint.toISOString();
            endpoint = endpoint.toISOString();
            axios.get(`${process.env.REACT_APP_API_URL}/v1/alarms/${selectedAlarm._id}/iotdata?startDate=${startpoint}&endDate=${endpoint}`,{ 
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  },
                }).then((res)=>{
                    
                    if(res.data?.AlarmWithIotData !== undefined){
                        if(res.data?.AlarmWithIotData[0] !== undefined ){

                            let alarm = res.data.AlarmWithIotData
                            let offset = Math.round(new Date().getTimezoneOffset() / 60); // To convert UTC timestamp to local timezone
                            let timeseries = []
                            if(selectedAlarm.alarmType !== "energy"){
                                timeseries = alarm.map(item => [new Date(item.timestamp),item.avg_value]);                        
                            }else{
                                timeseries = alarm.map((item,index) => {
                                    //console.log(item.avg_value - (data?.[index-1] ? data?.[index-1].avg_value: 0));
                                    if(index ===0) {
                                        return [new Date(item.timestamp), 0];
                                    }
                                    return [new Date(item.timestamp),item.avg_value - (alarm?.[index-1] ? alarm?.[index-1].avg_value: 0)];                    
                                })
                            }
                            let limitMin = selectedAlarm?.alarmHistory?.[selectedHistoryAlarm].tempLimits?.min !== null ? selectedAlarm?.alarmHistory?.[selectedHistoryAlarm].tempLimits?.min : null
                            let limitMax = selectedAlarm?.alarmHistory?.[selectedHistoryAlarm].tempLimits?.max !== null ? selectedAlarm?.alarmHistory?.[selectedHistoryAlarm].tempLimits?.max : null
                            let yaxisAnnotations = [];
                        
                            let fallbackYAxis = timeseries?.[0]?.[1] ? Math.floor(timeseries[0][1]):0;
                            if(selectedAlarm.alarmType === "energy"){
                                fallbackYAxis = Math.min(...timeseries.map((item)=> item[1]));
                            }
                            const deadband = selectedAlarm?.alarmHistory?.[selectedHistoryAlarm]?.deadband ? selectedAlarm?.alarmHistory?.[selectedHistoryAlarm]?.deadband : 0
                            const statusHistory = selectedAlarm?.alarmHistory?.[selectedHistoryAlarm]?.statusHistory?.map((status, index)=>{
                                let statusText = ""
                                const statusColor = severityColors?.[status.severity] || "#FF4560"; // Default color if status not found
                                if(status?.status){
                                    statusText = status.status.charAt(0).toUpperCase() + status.status.slice(1); // Capitalize first letter
                                }
                              
                                if(status.status !=="resolved"){
                                    const annotationx = {
                                        x: new Date(status.updatedAt).getTime() - (offset * 3600000),
                                        borderColor: statusColor,
                                        strokeDashArray: 0,
                                        label: {
                                          borderColor: statusColor,
                                          style: {
                                            color: "#fff",
                                            background: statusColor
                                          },
                                          orientation: "horizontal",
                                          text: statusText
                                        }
                                      };
                                    return annotationx
                                }
                            })
                            setAlarmHistorySensorData(
                                {
                                    sensordata:[{
                                        deviceId    :   selectedAlarm.deviceId,
                                        name        :   selectedAlarm.deviceId,
                                        data : timeseries,
                                        alarmtype : selectedAlarm.alarmType,
                                        
                                    }],
                                    
                                    options:{
                                        
                                        dataLabels: {
                                          enabled: false
                                        },
                                        chart: {
                                          id: "basic-bar",
                                          type:"line"
                                        },
                                        xaxis: {
                                          type: 'datetime',
                                          min: (new Date(selectedAlarm.alarmHistory[selectedHistoryAlarm].triggeredAt).getTime() - (offset * 3600000 )) - ((selectedAlarm.delayBeforeTriggeredInMinutes+1) * 1000 *60 *60)  ,
                                          max: (new Date(selectedAlarm.alarmHistory[selectedHistoryAlarm].resolvedAt).getTime() - (offset * 3600000 )) + ((selectedAlarm.delayBeforeTriggeredInMinutes+1) * 1000*60 * 60) ,
                                          
                                            
                                          
                                        },
                                        fill: {
                                            type: 'solid'
                                        },
                                        stroke: {
                                            show: true,
                                            curve: selectedAlarm.alarmType.alarmType !== "digital" ? "smooth": 'stepline',
                                            lineCap: 'butt',
                                            colors: undefined,
                                            width: 2,
                                            dashArray: 0, 
                                        },

                                        yaxis:{
                                            type:'integer',
                                            min: (limitMin ? limitMin  - 10 : fallbackYAxis - 10),
                                            max: (limitMax ? limitMax + 10 : fallbackYAxis + 10) ,
                                            labels: {
                                              formatter: function(val) {
                                                return val.toFixed(1);
                                              }
                                            },
                                            title:{
                                              text: "Temperatur i Celsius (℃)",
                                            },
                                        },
                                        
                                        tooltip:{
                                            y:{
                                                formatter: function(val){
                                                    return `${val.toFixed(2)}${selectedAlarm.alarmType === "temp" ? "℃" : ((selectedAlarm.alarmType === "kwh") ? ' kwh' :'')  }`
                                                }
                                            },
                                            x: {
                                                format: "dd.MM.yyyy - HH:mm"
                                            }
                                        
                                        },
                                        
                                        annotations: {
                                            xaxis : [
                                                {
                                                    x: new Date(selectedAlarm.alarmHistory[selectedHistoryAlarm].delayTriggeredAt).getTime()- (offset * 3600000 ),
                                                    borderColor: "#FF4560",
                                                    strokeDashArray: 1,
                                                    
                                                },
                                                {
                                                    x: new Date(selectedAlarm.alarmHistory[selectedHistoryAlarm].triggeredAt).getTime()- (offset * 3600000 ),
                                                    borderColor: "#FF4560",
                                                    strokeDashArray: 0,
                                                    label: {
                                                        borderColor: "#FF4560",
                                                        style: {
                                                            color: "#fff",
                                                            background: "#FF4560"
                                                          },
                                                        orientation: "horizontal",
                                                        text: `Utløst`
                                                      }
                                                },
                                                {
                                                    x: new Date(selectedAlarm.alarmHistory[selectedHistoryAlarm].resolvedAt).getTime() - (offset * 3600000 ),
                                                    borderColor: "#68cc6b",
                                                    strokeDashArray: 0,
                                                    label: {
                                                        borderColor: "#68cc6b",
                                                        style: {
                                                            color: "#fff",
                                                            background: "#68cc6b"
                                                          },
                                                        orientation: "horizontal",
                                                        text: `Løst`
                                                      }
                                                },
                                                ...statusHistory
                                            ],
                                            yaxis: [
                                              {
                                                y: limitMin ? limitMin : -500,
                                                y2: limitMax ? limitMax : 500,
                                                
                                                fillColor: '#68cc6b',
                                                label: {
                                                  text: `Grense [${limitMin ? limitMin : '<-'},${limitMax ? limitMax : '->'} ]`
                                                }
                                              },
                                              
                                              {
                                                y: limitMin - deadband,
                                                y2:limitMin + deadband,
                                                
                                                fillColor: '#eed202',
                                            
                                                label: {
                                                  text: "Deadband +/-"+ ( deadband),
                                                }
                                              },
                                              {
                                                y: limitMax + deadband,
                                                y2: limitMax - deadband,
                                                
                                                fillColor: '#eed202',
                                                
                                               
                                              }
                                            ]
                                          }
                                      }
                                }
                                

                            );
                            return;
                            
                            
                        }

                    }
                    return setAlarmHistorySensorData(
                        {
                            sensordata:[{
                                deviceId    :   selectedAlarm.deviceId,
                                name        :   selectedAlarm.name,
                                data : [],
                                alarmtype : selectedAlarm.alarmType
                            }],
                            options:{

                                dataLabels: {
                                  enabled: false
                                },
                                chart: {
                                  id: "basic-bar",
                                  type:"line"
                                  
                                },
                                xaxis: {
                                  type: 'datetime',
                                  min: new Date(selectedAlarm.alarmHistory[selectedHistoryAlarm].triggeredAt).getTime() - (selectedAlarm.delayBeforeTriggeredInMinutes * 1000 *60 *60)  ,
                                  max: new Date(selectedAlarm.alarmHistory[selectedHistoryAlarm].resolvedAt).getTime() + (selectedAlarm.delayBeforeTriggeredInMinutes * 1000*60 * 60) ,
                                  
                                },
                                fill: {
                                    type: 'solid'
                                },
                                stroke: {
                                    show: true,
                                    curve: selectedAlarm.alarmType !== "digital" ? "smooth": 'stepline',
                                    lineCap: 'butt',
                                    colors: undefined,
                                    width: 1,
                                    dashArray: 0, 
                                },
                                yaxis:{
                                    type:'integer',
                                    min: selectedAlarm.tempLimits.min - 10,
                                    max: selectedAlarm.tempLimits.max + 10,
                                    labels: {
                                      formatter: function(val) {
                                        return val.toFixed(0);
                                      }
                                    },
                                    title:{
                                      text: "Temperatur i Celsius (℃)",
                                    },
                                },
                                annotations: {
                                    xaxis : [
                                        {
                                            x: new Date(selectedAlarm.alarmHistory[selectedHistoryAlarm].triggeredAt).getTime(),
                                            borderColor: "#FF4560",
                                            strokeDashArray: 0,
                                            label: {
                                                borderColor: "#FF4560",
                                                style: {
                                                    color: "#fff",
                                                    background: "#FF4560"
                                                  },
                                                orientation: "horizontal",
                                                text: `Utløst`
                                              }
                                        },
                                        {
                                            x: new Date(selectedAlarm.alarmHistory[selectedHistoryAlarm].resolvedAt).getTime(),
                                            borderColor: "#68cc6b",
                                            strokeDashArray: 0,
                                            label: {
                                                borderColor: "#68cc6b",
                                                style: {
                                                    color: "#fff",
                                                    background: "#68cc6b"
                                                  },
                                                orientation: "horizontal",
                                                text: `Løst`
                                              }
                                        }
                                    ],
                                    yaxis: [
                                      {
                                        y: selectedAlarm.tempLimits.min,
                                        y2: selectedAlarm.tempLimits.max,
                                        
                                        fillColor: '#68cc6b',
                                        label: {
                                          text: `Grense [${selectedAlarm.tempLimits.min},${selectedAlarm.tempLimits.max} ]`
                                        }
                                      },
                                      
                                      {
                                        y: selectedAlarm.tempLimits.min - selectedAlarm.deadband,
                                        y2:selectedAlarm.tempLimits.min + selectedAlarm.deadband,
                                        
                                        fillColor: '#eed202',
                                    
                                        label: {
                                          text: "Deadband +/-"+ ( selectedAlarm.deadband),
                                        }
                                      },
                                      {
                                        y: selectedAlarm.tempLimits.max + selectedAlarm.deadband,
                                        y2: selectedAlarm.tempLimits.max - selectedAlarm.deadband,
                                        
                                        fillColor: '#eed202',
                                    
                                       
                                      }
                                    ]
                                },
                                tooltip:{
                                
                                    x: {
                                        format: "dd.MM.yyyy - HH:mm"
                                    }
                                         
                                },
                              }
                        }
                    )
                });

        }
    }
    const handleAlarmStatusUpdate = async (data) => {
        //e.preventDefault();
        const transformedData = {
            ...data,
            severity: data.severity.value,
          };
        console.log(transformedData)
        
        let alarmVal = selectedTriggeredAlarm._id;
        const accessToken = await getToken();
        try {
            // Send a POST request with Axios
            const response = await axios.post(process.env.REACT_APP_API_URL+'/v1/alarms/resolve-alarm/'+alarmVal, 
                transformedData, 
                {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then(()=> {
                setShowTriggeredAlarm(false)
                setSelectedTriggeredAlarm(null)
                getTriggeredAlarms();
                refreshTriggeredAlarms();
                getAlarms();
                reset();
            });
        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
        }
    }
    const handleAlarmStatusUpdateByButton = async (status,severity, id) => {
        let alarmVal = id;
        //resolved , success,
        // Godkjent , warning
        const accessToken = await getToken();
        try {
            // Send a POST request with Axios
            if(!alarmVal) return;
            const response = await axios.post(process.env.REACT_APP_API_URL+'/v1/alarms/resolve-alarm/'+alarmVal, 
                {
                    actionType: status,
                    severity : severity,
                }, 
                {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }).then(()=> {
                setShowTriggeredAlarm(false)
                setSelectedTriggeredAlarm(null)
                getTriggeredAlarms();
                refreshTriggeredAlarms();
                getAlarms();
                reset();
            });
        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
        }
    }
    useEffect(()=>{
        if(selectedAlarm?._id !== undefined){
            fetchAlarmHistoryIotData(selectedHistoryAlarm);
        }
    },[selectedHistoryAlarm])
    // Create "Kvitter ut" function that makes alarm approved/fixed.
    return(
        
        <div className="container-fluid">
            <div className="row">

                {triggeredAlarms?.length >0 ? (
                <Card variant="light" title="Utløste alarmer" size="12">

                    <div className="mobile-only">
                        {triggeredAlarms?.map((alarm, i) => {
                            let typeUnit = "";
                            let typeText = "";
                            let triggeredAt =  new Date(alarm.triggeredAt).toLocaleString('no-NO')

                            if(alarm.alarmType === "temp") {
                                typeUnit = alarm?.device?.measurementTypeUnit ?? '';
                                typeText = "Sanntidsmåling";
                            } else if(alarm.alarmType === "kwh") {
                                typeUnit = alarm?.device?.measurementTypeUnit ?? '';
                                typeText = "Akkumulert";
                            }else if (alarm.alarmType === "digital"){
                                typeUnit = "0/1";
                                typeText = "Digital"
                            }

                            return(
                                <div className="triggered-flex" key={i}>
                                    <div className="trig-name">
                                        <small style={{color:'gray'}}>{alarm?.device?.building?.name}</small>
                                        <h5>{alarm.name}</h5>
                                    </div>

                                    <div className="trig-device">
                                        <strong>
                                            {alarm.sensorId}
                                        </strong> <br />
                                        <p className="smaller">
                                            {typeText} ({typeUnit})
                                        </p>
                                    </div>

                                    <div className="trig-when">
                                        <strong>{moment(alarm.triggeredAt).locale("NO").local().startOf('seconds').fromNow()}</strong><br />
                                        {triggeredAt ? triggeredAt : ' '}
                                    </div>

                                    <div className="trig-action">
                                        <Link to={"/devices/" + alarm.device._id} className="select-btn secondary-action-btn">Sensor</Link>
                                        <button onClick={(e)=>{handleSelectTriggeredAlarm(e,alarm)}} value={alarm._id} className="select-btn me-1">Detaljer</button>
                                    </div>
                                </div>

                            );
                        })}
                    </div>

                    <div className="desktop-only">
                        <div className="alarmlist-wrapper">
                            <table className="infotable">
                                <thead>
                                    <tr>
                                        <th>Navn</th>
                                        <th>Sensor ID</th>
                                        <th>Status</th>
                                        <th><i className="fa-sharp fa-light fa-hexagon-exclamation"></i>  Utløst</th>
                                        <th></th>
                                    </tr>
                                </thead>
                    
                                <tbody>
                                    {triggeredAlarms?.map((alarm, i) => {
                                        //console.log(alarm);
                                        let typeUnit = "";
                                        let typeText = "";
                                        let triggeredAt =  new Date(alarm.triggeredAt).toLocaleString('no-NO')

                                        if(alarm.alarmType === "temp") {
                                            typeUnit = alarm?.device?.measurementTypeUnit ?? '';
                                            typeText = "Sanntidsmåling";
                                        } else if(alarm.alarmType === "kwh") {
                                            typeUnit = alarm?.device?.measurementTypeUnit ?? '';
                                            typeText = "Akkumulert";
                                        }else if (alarm.alarmType === "digital"){
                                            typeUnit = "0/1";
                                            typeText = "Digital"
                                        }
            
                                        const lastStatus = alarm.currentStatusHistory?.length > 0 ? alarm.currentStatusHistory[alarm.currentStatusHistory.length - 1] : null;
                                        return(
                                            <tr key={i}>          
                                                <td>
                                                    <small style={{color:'gray'}}>{alarm?.device?.building?.name}</small>
                                                    <br/>
                                                    <h5>{alarm.name}</h5>
                                                </td>
                                                <td>
                                                    <strong>
                                                        {alarm.sensorId}
                                                    </strong> <br />
                                                    <p className="smaller">
                                                        {typeText} ({typeUnit})
                                                    </p>
                                                </td>
                                                <td>
                                                {lastStatus ? (
                                                    <div className={`label ${lastStatus.severity}`}>
                                                        {lastStatus.status}
                                                    </div>
                                                ) : (
                                                    <div className="label danger">
                                                        Utløst
                                                    </div>
                                                )}
                                                </td>
                                                <td>
                                                    <div>
                                                        <strong>{moment(alarm.triggeredAt).locale("NO").local().startOf('seconds').fromNow()}</strong><br />
                                                        {triggeredAt ? triggeredAt : ' '}
                                                    </div>
                                                </td>
                                                <td>
                                                    <button onClick={(e)=>{handleSelectTriggeredAlarm(e,alarm)}} value={alarm._id} className="select-btn me-1">Detaljer</button>
                                                    {/*<Link to={"/devices/" + alarm.device._id} className="select-btn secondary-action-btn">Sensor</Link>*/}
                                                    
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card>
                ):''}
                
                <div style={{height: "35px"}}></div>
                {selectedAlarm !== null ? 
                    <>
                        
                        <Card style={{marginTop: "25px" }}variant="light" title={selectedAlarm.name + " - Historikk - " + selectedAlarm.sensorId} size="12">
                            <table className="infotable">
                                <thead>
                                    <th><i className="fa-sharp fa-light fa-triangle-exclamation"></i> Utløst</th>
                                    <th>Status</th>
                                    <th>Kvittert ut</th>
                                    <th>Handlinger</th>
                                </thead>

                                <tbody>
                                    {getCurrentPageData().map((alarmHistory, i) => {

                                        let resolveClass = "danger";
                                        let resolveText = "Varsel";

                                        if(alarmHistory.resolved === true) {
                                            resolveClass = "success";
                                            resolveText = "Løst";
                                        }

                                        return(
                                            <tr>
                                                <td>{alarmHistory.triggeredAt ? moment(new Date(alarmHistory.triggeredAt)).format("YYYY-MM-DD, H:mm") : ""}</td>
                                                <td><div className={"label " + resolveClass}>{resolveText}</div></td>
                                                <td>
                                                        {alarmHistory.resolvedAt ? moment(new Date(alarmHistory.resolvedAt)).format("YYYY-MM-DD, H:mm") : ""}
                                                </td>
                                                <td><button onClick={handleSelectHistory} value={i} className="select-btn">Detaljer</button></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                            <span className="page-view">
                                Side {currentPage} av {totalPages}
                            </span>  

                            <div className="pagination-controls">
                                <button
                                onClick={handlePreviousPage}
                                disabled={currentPage <= 1}
                                >
                                <i className="fa-light fa-circle-chevron-left"></i>
                                </button>

                                {renderPageNumbers()}

                                <button
                                onClick={handleNextPage}
                                disabled={currentPage >= totalPages}
                                >
                                <i className="fa-light fa-circle-chevron-right"></i>
                                </button>
                            </div>


                            {selectedHistoryAlarm ? 
                            <>
                                <div id="graph-modal">
                                    <div className="overlay" onClick={handleDeselectHistory}></div>

                                    <div className="content">
                                        {(alarmHistorySensorData.options  && alarmHistorySensorData.sensordata !=undefined ) ? (
                                            <>
                                                <Chart 
                                                options={alarmHistorySensorData.options}
                                                series={alarmHistorySensorData.sensordata}
                                                type="line"
                                                width="100%"
                                                />
                                            </>
                                            
                                            
                                        ) : "" }
                                    </div>
                                </div>
                                
                            </> : ""}

                        </Card>
                        </>
                : ""}

                <Card variant="light" title="Alarmliste" size="12">

                    <div className="mobile-only">
                    {alarms?.map((alarm, i) => {
                        
                        return(
                            <div className="flex-information" key={i}>
                                <div className="flex-box">
                                    <strong>{alarm.name}</strong><br />
                                    {alarm.description}
                                </div>

                                <div className="flex-box">
                                    <ul className="info-list">
                                        <li>Sensor: {alarm.sensorId}</li>
                                        <li>Deadband: {alarm.deadband}</li>
                                        <li>Min: {alarm.tempLimits.min}</li>
                                        <li>Max: {alarm.tempLimits.max}</li>
                                    </ul>
                                </div>

                                <div className="flex-action wide">
                                    <Link to={"/alarms/" + alarm._id + "/edit/"} className="select-btn secondary-action-btn">Rediger</Link>
                                    <button onClick={handleSelect} value={alarm._id} className="select-btn">Velg</button>
                                </div>
                            </div>
                        )
                    })}
                    </div>
                    
                    <div className="desktop-only">
                        <table className="infotable">
                            <thead>
                                <tr>
                                    <th>Navn</th>
                                    <th>Sensor ID</th>
                                    <th>Deadband</th>
                                    <th><i class="fa-sharp fa-regular fa-circle-arrow-down"></i> Minimumsgrense</th>
                                    <th><i class="fa-sharp fa-regular fa-circle-arrow-up"></i> Maksimumsgrense</th>
                                    <th><i className="fa-sharp fa-light fa-clock"></i> Delay</th>
                                    <th>Handlinger</th>
                                </tr>
                            </thead>

                            <tbody>
                                {alarms?.map((alarm, i) => {
                                    let typeUnit = "";
                                    let typeText = "";
                                    if(alarm.alarmType === "temp") {
                                        typeUnit = "°C";
                                        typeText = "Temperatur";
                                    } else if(alarm.alarmType === "kwh") {
                                        typeUnit = "kwh";
                                        typeText = "Kilowattimer";
                                    }else if (alarm.alarmType === "digital"){
                                        typeUnit = "0/1";
                                        typeText = "Digital"
                                    }
                                    return(
                                        <tr key={`alarm-list-${i}`}>
                                            <td>
                                                <small style={{color:'gray'}}>{alarm?.device?.building?.name}</small>
                                                <br/>
                                                <h5>{alarm.name}</h5>
                                            </td>
                                            <td>
                                                <strong>
                                                    {alarm.sensorId}
                                                </strong> <br />
                                                <p className="smaller">
                                                    {typeText} ({typeUnit})
                                                </p>
                                            </td>
                                            <td>{alarm.alarmType !== "digital" ? alarm.deadband:''}</td>
                                            <td>{alarm.alarmType !== "digital" ? alarm.tempLimits.min:''}</td>
                                            <td>{alarm.alarmType !== "digital" ? alarm.tempLimits.max:''}</td>
                                            <td>{alarm.delayBeforeTriggeredInMinutes} min</td>
                                            <td>
                                                <Link to={"/alarms/" + alarm._id + "/edit/"} type="button" className="select-btn secondary-action-btn">Rediger</Link>
                                                <button onClick={handleSelect} value={alarm._id} className="select-btn">Velg</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    

                </Card>

                <div className="col-sm-12">
                    <Link className="button create" to="/alarms/create">Opprett alarm</Link>
                </div>
                
                <Modal show={showTriggeredAlarm} onHide={handleClose} size="xl">
                    <Modal.Header >
                        <Modal.Title>{selectedTriggeredAlarm?.name} - ({(selectedTriggeredAlarm?.device?.name)})</Modal.Title>
                        <i className="fa-sharp fa-light fa-xmark" style={{fontSize:'18px'}}  onClick={handleClose}></i>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                                <div className="col-md-8">
                                    {isLoading ? (
                                        <>loading</>
                                    ):(
                                    <>
                                    {(triggeredAlarmSensorData.options  && triggeredAlarmSensorData.sensordata !=undefined ) ? (
                                        <>
                                            
                                            <Chart 
                                            options={triggeredAlarmSensorData.options}
                                            series={triggeredAlarmSensorData.sensordata}
                                            width="100%"
                                            />
                                        </>
                                    ) : "" }    
                                    </>
                                    )}
                                    
                                </div>
                                <div className="col-md-4">
                                    <form  className="update-status-form">
                                        <div>
                                            <h4>
                                                Håndter alarm
                                            </h4>
                                            <hr></hr>
                                        </div>
                                        <h6>Enhet: {selectedTriggeredAlarm?.device?.name}</h6>    
                                        <small style={{color:'gray'}}>Bygg: {selectedTriggeredAlarm?.device?.building?.name}</small>
                                        <small style={{color:'gray'}}>Adresse: {selectedTriggeredAlarm?.device?.building?.address}</small>
                                        <small style={{color:'gray'}}>Sted: {selectedTriggeredAlarm?.device?.building?.city}, {selectedTriggeredAlarm?.device?.building?.zip} </small>

                                            <Link to={`/devices/${selectedTriggeredAlarm?.device?._id}`} style={{textAlign:'center',display:'inline-block','width':'auto',textDecoration:'none'}} type="button" className="select-btn secondary-action-btn">Se enhet</Link>
                                                {/* <Controller
                                                control={control}
                                                name={`actionType`}
                                                render={({field:{onChange,value}}) => (
                                                    
                                                    <Select className=""
                                                        required
                                                        onChange={(option) => {
                                                            console.log(option)
                                                            if(option.value === "Godkjent"){
                                                                setValue("severity",alarmStatusOptions[1])
                                                            }else if(option.value === "Avventer"){
                                                                setValue("severity",alarmStatusOptions[2])
                                                            }else if (option.value === "resolved"){
                                                                setValue("severity",alarmStatusOptions[3])
                                                            }
                                                            onChange(option.value)
                                                        }}

                                                        placeholder={"Velg eller skriv inn statusmelding"}
                                                        options={[
                                                            {value:"Godkjent",label:"Godkjent/sett"},
                                                            {value:"Avventer",label:"Avventer"},
                                                            {value:"resolved",label:"Kvitter ut"}
                                                        ]} 
                                                        
                                                        >
                                                    </Select>
                                                )}
                                                />
                                          
                                            <Controller
                                                control={control}
                                                
                                                name={`severity`} render = {({field})=>
                                                    <>
                                                    <Select
                                                        {...field}
                                                        isSearchable={false}
                                                        placeholder={"Velg farge"}
                                                        options={alarmStatusOptions}
                                                        styles={colourStyles}
                                                        />
                                                        </>
                                                }
                                                /> */}
                                                
                                            <div className="form-bottom-adjust">

                                                {/* 
                                                <label htmlFor="comment" className="mt-4"><i className="fa-sharp fa-light fa-circle-info"></i> Kommentar</label>
                                                <textarea rows={4} {...register('comment')} className="form-control " style={{display:'none'}} placeholder="Skriv en kommentar her..."></textarea>
                                                */}
                                                <div className="buttons-modal">
                                                    <button type="button" onClick={(e)=> {e.preventDefault();handleAlarmStatusUpdateByButton('Godkjent','warning',selectedTriggeredAlarm._id)}} className="button create">Godkjenn </button>
                                                    <button type="button" onClick={(e)=> {e.preventDefault();handleAlarmStatusUpdateByButton('resolved','success',selectedTriggeredAlarm._id)}} className="button create">Kvitter ut <i class="fa-light fa-box-archive"></i></button>
                                                </div>
                                               
                                            </div>
                                        
                                            

                                    </form>
                                </div>
                        </div>
                    </Modal.Body>
                </Modal>
                
            </div>
        </div>
    );
}

export default Alarm;