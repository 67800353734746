import React, { useState, useEffect } from "react";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Link } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";

let routes = [
    { path: '/', breadcrumb: 'Hjem' },
    { path: '/alarm', breadcrumb: 'Alarmliste' },
    { path: '/alarms/create', breadcrumb: 'Opprett' },
    { path: '/alarms/:alarmId/', breadcrumb: null}, // Alarm navn her?
    { path: '/alarms/:alarmId/edit', breadcrumb: 'Rediger'},
    { path: '/buildings', breadcrumb: 'Alle bygg' },
    { path: '/buildings/:buildingId/', breadcrumb: null}, // Bygningsnavn her?
    { path: '/buildings/:buildingId/devices/', breadcrumb: "Enheter" },
    { path: '/buildings/:buildingId/devices/create', breadcrumb: 'Opprett' }, 
    { path: '/buildings/:buildingId/devices/:deviceId/edit', breadcrumb: 'Rediger' },
    { path: '/buildings/:buildingId/edit/', breadcrumb: "Rediger" },
    { path: '/buildings/create', breadcrumb: 'Opprett' },
    { path: '/unit/create', breadcrumb: 'Opprett' },
    { path: '/account', breadcrumb: 'Min konto' },
];

function getBuildingIdFromURL() {
  const path = window.location.pathname;
    //console.log(path)
  const match = path.match(/\/buildings\/([^/]+)/);
    //console.log(match)
  if (match) {
    return match[1];
  }

  return null;
}

const buildingId = getBuildingIdFromURL();

function BreadCrumbs() {

    const { getToken } = useKindeAuth();
    const [breadCrumbs , setBreadCrumbs] = useState([ 
        { path: '/', breadcrumb: 'Hjem' },
        { path: '/alarm', breadcrumb: 'Alarmliste' },
        { path: '/alarms/create', breadcrumb: 'Opprett' },
        { path: '/alarms/:alarmId/', breadcrumb: null}, // Alarm navn her?
        { path: '/alarms/:alarmId/edit', breadcrumb: 'Rediger'},
        { path: '/buildings', breadcrumb: 'Alle bygg' },
        { path: '/buildings/:buildingId/', breadcrumb: null}, // Bygningsnavn her?
        { path: '/buildings/:buildingId/devices/', breadcrumb: "Enheter" },
        { path: '/buildings/:buildingId/devices/create', breadcrumb: 'Opprett' }, 
        { path: '/buildings/:buildingId/devices/:deviceId/edit', breadcrumb: 'Rediger' },
        { path: '/buildings/:buildingId/edit/', breadcrumb: "Rediger" },
        { path: '/buildings/create', breadcrumb: 'Opprett' },
        { path: '/unit/create', breadcrumb: 'Opprett' },
        { path: '/account', breadcrumb: 'Min konto' },]
    );
    const [isLoading, setIsLoading] = useState(true);
    
    const getBuilding = async () => {
        if(buildingId !== null) {
            const accessToken = await getToken();
            axios
                .get(`${process.env.REACT_APP_API_URL}/v1/buildings/${buildingId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                .then((res) => {
                    
                    const apiBuilding = res.data;
                    setIsLoading(false); // Set loading to false when data is loaded.
                    routes[4].breadcrumb = apiBuilding.name;
                    routes[6].breadcrumb = apiBuilding.name;
                    //console.log(routes)

                });
        }
    }

    useEffect(() => {
        getBuilding(getBuildingIdFromURL());
    }, []);


    const breadcrumbs = useBreadcrumbs(routes);
    /** THIS HAS SOME BUGS SO TURNED OFF FOR NOW */
    /*
    return (
        <nav>
            <ul>
                {breadcrumbs.map(({ match, breadcrumb }, index) => (
                    <li key={match.pathname}>
                        <Link key={match.pathname} to={match.pathname}>
                            {index === 0 ? (
                                <i className="fa-sharp fa-light fa-house firstCrumb"></i>
                            ) : (
                                <>
                                    <i className="fa-sharp fa-regular fa-chevron-right"></i>
                                    {breadcrumb}
                                </>
                            )}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
    */
    
}

export default BreadCrumbs;