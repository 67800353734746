
import React, { useState, useEffect } from "react";
// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import { useGlobalState } from "../GlobalStateContext";

// We import NavLink to utilize the react router.
import { NavLink } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import SearchResults from "./SearchResults";
 
// Here, we display our Navbar
export default function Navbar() {

  const [input, setInput] = useState("");
  const {user, isAuthenticated} = useKindeAuth();

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { aquaflowUser, refreshTriggeredAlarms, triggeredAlarmsCount } = useGlobalState();
  const { getToken } = useKindeAuth();
  const [searchResults, setSearchResults] = useState('');

  const handleInputChange = (e) => {
    setInput(e.target.value);
  }
  const nullifySearch = () => {
    setInput("")
  }

  useEffect(()=> {
    freeSearch(input)
  },[input])
  
  useEffect(() => {
      refreshTriggeredAlarms();
      const interval = setInterval(refreshTriggeredAlarms, 20000);
      // Clear interval on component unmount to avoid memory leaks
      return () => clearInterval(interval);
  }, []);

  const handleShowMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  }
  const freeSearch = async(query) => {
    const accessToken = await getToken();
    if(input !== ""){
      setSearchResults(
        <div className="search-results">
          <div className="result-line">
            Søker ...
          </div>
        </div>
      )
      axios.get(`${process.env.REACT_APP_API_URL}/v1/search?query=${query}`,{ 
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((response)=> {
          const data = response.data;
          //console.log(response.data);
          let empty = true;
          // Loop through each category and its results
          Object.entries(data).forEach(([modelName, modelResults]) => {
            if (modelResults.length > 0) {
              empty = false;
            }
          });
          //console.log(`Highest score (${highestScore}) in ${highestCategory}`);
          //console.log(`Second highest score (${secondHighestScore}) in ${secondHighestCategory}`);
          setSearchResults(<SearchResults results={data} empty={empty} query={query} />)
      })
    }

  }

 return (
   <div>
    <header className="header-nav" id="masthead">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-sm-12 my-auto">

            <nav className="custom-navbar">
              <NavLink className="navbar-brand" to="/">
                <img src="/img/logo-landscape-white.png" width="135" height="auto" />
              </NavLink>

              <label className="search-wrapper" id="search-wrapper">
                <i className="fa-sharp fa-light fa-magnifying-glass"></i>
                <input onChange={handleInputChange} value={input} onBlur={(e)=>{setTimeout(()=>{
                  nullifySearch()
                },200)}} type="search" placeholder="Søk her..." />
                {input !== "" ? 
                <div id="srcResult">
                  {searchResults}
                </div>

                : ""}
              </label>
    
              <ul>
                
                <li className="nav-item text-link">
                  <NavLink className="nav-link" to="/">
                    Oversikt
                  </NavLink>
                </li>

                                
                <li className="nav-item text-link">
                  <NavLink className="nav-link" to="/buildings">
                  Bygg
                  </NavLink>
                </li>
                <li className="nav-item text-link">
                  <NavLink className="nav-link" to="/devices">
                  Enheter
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/alarms">
                    <div className="circle-nav">
                      <i className={`fa-sharp fa-solid fa-bell${triggeredAlarmsCount > 0 ? '-on':''}`}></i>
                      {triggeredAlarmsCount > 0 && (
                        <div className="alarm-number">
                          <div className="inner-number">{triggeredAlarmsCount}</div>
                        </div>
                      )}
                    </div>
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/account">
                    <div className="circle-nav">
                      <i className="fa-sharp fa-solid fa-user"></i>
                    </div>
                  </NavLink>
                </li>

                <li className="nav-item mobMenu" onClick={handleShowMenu}>
                    <div className="circle-nav">
                      <i className="fa-sharp fa-solid fa-bars"></i>
                    </div>
                </li>


              </ul>
            </nav>
            <div id="mobileMenu" className={showMobileMenu ? "active" : ""}>
                <div className="circle-nav" id="closeMenu" onClick={handleShowMenu}>
                  <i className="fa-sharp fa-solid fa-xmark"></i>
                </div>

              <ul>
                <li className="nav-item text-link">
                  <NavLink className="nav-link" onClick={handleShowMenu} to="/">
                    Oversikt
                  </NavLink>
                </li>

                <li className="nav-item text-link">
                  <NavLink className="nav-link" onClick={handleShowMenu} to="/buildings">
                  Bygg
                  </NavLink>
                </li>
                <li className="nav-item text-link">
                  <NavLink className="nav-link" onClick={handleShowMenu} to="/devices">
                    Enheter
                  </NavLink>
                </li>
              </ul>
            </div>

          </div>
        </div>  
      </div>
      </header>
    </div>

 );
}