import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "../components/card";
import ApexCharts from 'apexcharts'

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from "axios";
import { useGlobalState } from "../GlobalStateContext";
import EnergyBarRankingChart from "../components/charts/EnergyBarRankingChart";
import EnergyPieChart from "../components/charts/EnergyPieChart";
import StackedEnergyBarChart from "../components/charts/StackedEnergyBarChart";

function Dashboard() {
    const { getToken } = useKindeAuth();
    const { dateFilter, updateDateFilter, setStartDate, setEndDate } = useGlobalState();
    const [buildings, setBuildings] = useState([]);
    const [isLoadingCharts,setIsLoadingCharts] = useState(true);
    const [buildingsEnergyData, setBuildingsEnergyData] = useState([]);
    const [energyBraChart, setEnergyBraChart] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const getBuildings = async() =>{
        const accessToken = await getToken();
        axios.get(process.env.REACT_APP_API_URL+'/v1/buildings', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(res => {
                const apiBuildings = res.data;
                setBuildings(apiBuildings);
        });
    }
    const getBuildingsEnergyData = async() => {
        const accessToken = await getToken();
        axios.get(process.env.REACT_APP_API_URL+`/v1/iotdata/building-energy-data?startDate=${dateFilter.startDate}&endDate=${dateFilter.endDate}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(res => {
            const energyData = res?.data?.map((building,index)=> {
                const categoryData = {
                    "Oppvarming":{
                        name:"Oppvarming",
                        value: Math.ceil(Math.random()*5000)
                    },
                    "Varmtvann":{
                        name:"Varmtvann",
                        value: Math.ceil(Math.random()*5000)
                    },
                    "Vifter og pumper": {
                        name:"Vifter og pumper",
                        value: Math.ceil(Math.random()*5000)
                    },
                    "Belysning":{
                        name:"Belysning",
                        value: Math.ceil(Math.random()*5000)
                    },
                    "Teknisk utstyr": {
                        name:"Teknisk utstyr",
                        value: Math.ceil(Math.random()*5000)
                    },
                    "Kjøling":{
                        name:"Kjøling",
                        value: Math.ceil(Math.random()*5000)
                    },
                    "Utendørs":{
                        name:"Utendørs",
                        value: Math.ceil(Math.random()*5000)
                    },
                    "Ukategorisert":{
                        name:"Ukategorisert",
                        value: Math.ceil(Math.random()*5000)
                    },
                };
                let returndata = building;
                building?.devices?.map((device,idx) => {
                    let category = device.category;
                    const iotData = device.iotData;
                    if(!category){
                        category = "Ukategorisert"
                    }
                    
                    if(!categoryData?.[category]){
                        categoryData[category] = { name:category, value: 0, iotData:iotData ? iotData : []};
                    }
                    
                    if(iotData.length >0){
                        categoryData[category].value += Math.ceil(iotData.reduce((sum,curr) => sum + curr.avg_value,0) / iotData.length);
                    }
                    //Just for testing
                    
                })
                returndata.categories = categoryData;
                returndata.totalEnergyUsage = Object.values(building.categories).reduce((sum, category) => sum + category.value, 0);
                returndata.totalEnergyUsagePerBra = building.sizeBra ? returndata.totalEnergyUsage / building.sizeBra : 0;
                return returndata
            });
            
            //energyData.sort((a, b) => a.totalEnergyUsage - b.totalEnergyUsage)
            setBuildingsEnergyData(energyData.sort((a, b) => a.totalEnergyUsage - b.totalEnergyUsage));
        });
    }

  useEffect(() => {
      getBuildings();
  }, []);
  useEffect(()=>{
    setIsLoadingCharts(false);
  },[buildingsEnergyData])
  useEffect(()=>{
    if(dateFilter.startDate && dateFilter.endDate){
        setIsLoadingCharts(true);
        getBuildingsEnergyData();
    }
    },[dateFilter.startDate,dateFilter.endDate]);



    return (
      <div className="container-fluid space">
         {/* <div className="row">
            <Card variant="light" title={<>Energiforbruk per m<sup>2</sup></>} size="5">
                {buildingsEnergyData &&
                 <EnergyBarRankingChart data={buildingsEnergyData} />
                } 
            </Card>
            <Card variant="light" title={<>Totalt energiforbruk per kategori</>} size="7">
                
                {buildingsEnergyData &&
                    <StackedEnergyBarChart data={buildingsEnergyData} />
                }
            </Card>
        </div> */}
        <div className="row ">
        <Card variant="light" title={<>Energiforbruk per m<sup>2</sup></>} size="5">
                {buildingsEnergyData &&
                 <EnergyBarRankingChart data={buildingsEnergyData} />
                } 
            </Card>
        <Card variant="light" title={<>Totalt energiforbruk per kategori</>} size="7">
                
                {buildingsEnergyData &&
                    <EnergyPieChart data={buildingsEnergyData} />
                }
            </Card>
        </div>
        <div className="row mt-3">
          <Card variant="light" title="Bygg" size="12">
          <div className="mobile-only">
              {buildings.map((building, i) => {
                    return (
                      <div key={i} className="flex-information">
                          <div className="flex-box">
                            <strong>{building.name}</strong><br />
                            {building.organization?.[0]?.name}
                          </div>

                          <div className="flex-box">
                            {Array.isArray(building.devices) ? building.devices.length : 0} 
                            {building.devices.length >= 2 ? " enheter" : " enhet"} 
                          </div>

                          <div className="flex-action">
                            <Link to={"/buildings/" + building._id} className="select-btn">Velg</Link>
                          </div>
                      </div>

                    );
                  })}
            </div>

                <div className="desktop-only">
                    <table className="infotable">
                        <thead>
                            <tr>
                            <th>Bygg</th>
                            <th>Etasjer</th>
                            <th>BRA</th>
                            <th>BTA</th>
                            <th>Enheter</th>
                            <th>Handlinger</th>
                            </tr>
                        </thead>

                        <tbody>
                        {buildings?.map((building, i) => {
                            return(
                                <tr key={`building-${i}`}>
                                    <td>
                                        <strong>{building.name}</strong><br />
                                        {building.address}
                                    </td>
                                    <td>{building?.floors?.length}</td>
                                    <td>{building?.sizeBra ? (<>{building?.sizeBra} m<sup>2</sup></>) :''}</td>
                                    <td>{building?.sizeBta ? (<>{building?.sizeBta} m<sup>2</sup></>) :''}</td>
                                    <td>{building?.devices?.length}</td>
                                    <td><Link className="select-btn" to={"/buildings/" + building._id}>Velg</Link></td>
                                </tr>
                            );
                        }) }
                        </tbody>
                    </table>
                </div>
            
            </Card>
        </div>
       
      </div>
    );
  
}

export default Dashboard;




  
    