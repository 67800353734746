import React, { useState, useEffect } from "react";
import Card from "../../components/card";
import Row from "../../components/row";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useGlobalState } from "../../GlobalStateContext";
import DeviceAlarmHistory from "./components/DeviceAlarmHistory";
import moment from "moment";

function Device() {
    const { getToken } = useKindeAuth();
    const [device, setDevice] = useState([]);
    const [building, setBuilding] = useState([]);
    const { deviceId } = useParams(); 
    const [lastRecordedValue, setLastRecordedValue] = useState(null);
    const { dateFilter, updateDateFilter, setStartDate, setEndDate } = useGlobalState();
    const [linechart, setLinechart] = useState({
        options:{ 
            chart:{
                id:'line-graph-period',
                type:'line'
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                type: 'datetime',
            },
        },
        series:undefined
    });
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState(false);
    const [heatmap, setHeatMap] = useState({
        options:{
            chart:{
                type:'heatmap'
            },
            dataLabels: {
                enabled: false
              },
            xaxis: {
                type: "category",
                categories: [
                  0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23
                ]
            },
            colors: ["#ff7e00"],
            title: {
              text: "",
            },
            tooltip:{
                y:{
                    formatter: function(val){
                        return `${val.toFixed(2)}${device.measurementTypeUnit  ? device.measurementTypeUnit : ''}`
                    }
                }
            },
           
          },
        series:[],
    });
   
    const getDeviceIotData = async (startDate,endDate)=> {
        const accessToken = await getToken();
        axios.get(`${process.env.REACT_APP_API_URL}/v1/devices/${deviceId}?include_alarms=1`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(res => {
            const apiDevice = res.data[0];
            setDevice(apiDevice);
            if (apiDevice.building) {
                getBuilding(apiDevice.building);
            }
            axios.get(`${process.env.REACT_APP_API_URL}/v1/devices/${deviceId}/iotdata?startDate=${startDate}&endDate=${endDate}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                                
                let minValue = 10000
                let maxValue = -10000
                const data = response.data.DeviceWithIotData;
                if(data){
                    setLastRecordedValue(data?.[data.length-1]?.avg_value);
                }
                // Format data for ApexCharts
                const categories = data.map(item => item.timestamp);
                //console.log(response.data.DeviceWithIotData);
                let countseries =[];
                let heatmapData = [];
                const startDate = new Date(dateFilter.startDate);
                const endDate = new Date(dateFilter.endDate);
                const allHours = [];
                const currentDate = new Date(startDate);
                while (currentDate <= endDate) {
                    allHours.push(new Date(currentDate));
                    currentDate.setDate(currentDate.getDate() + 1);
                }
    
                    // Initialize grouped data map with entries for all dates
                const groupedDataMap = new Map();
                allHours.forEach(day => {
                    const dayTimestamp = new Date(day.getFullYear(), day.getMonth(), day.getDate()).getTime();
                    groupedDataMap.set(dayTimestamp, {
                        name: moment(day).format('DD.MM'),
                        data: Array.from({ length: 24 }, (_, hour) => (0)),
                    });
                });
                
    
                // Update grouped data with actual values
                
                /* 
                
                */
                // Convert map values to array
               
                let timeseries = [];
                let previous_value = null;
               
                switch (apiDevice.measurementType) {
                    case "energy":
                        let sum = 0;
                        timeseries = data.map((item,index) => {
                            if (index === 0){
                                sum = data?.[index]?.avg_value
                                return [new Date(item.timestamp),0];
                                }
                            //sum += item.avg_value;
                            let calculated_value = item.avg_value - data?.[index-1]?.avg_value ;
                            if(calculated_value < 0){
                                calculated_value += 32768;
                            }
                            sum += calculated_value;
                            
                            return [new Date(item.timestamp),calculated_value];
                            /*
                            return [new Date(item.timestamp),sum];
                            return [new Date(item.timestamp),item.avg_value];
                            */
                        });
                        data.forEach((item,index) => {
                            const timestamp = new Date(item.timestamp);
                            const dayTimestamp = new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate()).getTime();
            
                            if (groupedDataMap.has(dayTimestamp)) {
                                const dayData = groupedDataMap.get(dayTimestamp);
                                const hour = timestamp.getHours();
                                const calculated_value = ( index === 0) ? 0 : item.avg_value - data?.[index-1].avg_value
                                dayData.data[hour] = calculated_value;
                                minValue = 0
                                if(calculated_value > maxValue){
                                    maxValue = calculated_value
                                }
                            }
                        });
                        
                        break;
                    case "digital":
                        let tempValue = data?.[0].avg_value;
                        timeseries = data.map((item,index) => {
                            return [new Date(item.timestamp),item.avg_value]; 
                        })
                        data.forEach((item,index) => {
                            const timestamp = new Date(item.timestamp);
                            const dayTimestamp = new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate()).getTime();
            
                            if (groupedDataMap.has(dayTimestamp)) {
                                const dayData = groupedDataMap.get(dayTimestamp);
                                const hour = timestamp.getHours();
                                dayData.data[hour] = item.avg_value;
                                if(item.avg_value< minValue){
                                    minValue = item.avg_value;
                                }
                                if(item.avg_value > maxValue){
                                    maxValue = item.avg_value
                                }
                            }
                        });
                        break;
                    default:
                        data.forEach((item,index) => {
                            const timestamp = new Date(item.timestamp);
                            const dayTimestamp = new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate()).getTime();
            
                            if (groupedDataMap.has(dayTimestamp)) {
                                const dayData = groupedDataMap.get(dayTimestamp);
                                const hour = timestamp.getHours();
                                dayData.data[hour] = item.avg_value;
                                if(item.avg_value< minValue){
                                    minValue = item.avg_value;
                                }
                                if(item.avg_value > maxValue){
                                    maxValue = item.avg_value
                                }
                            }
                        });
                        timeseries = data.map(item => [new Date(item.timestamp),item.avg_value]);
                        break;
                }
                //console.log(timeseries);
                
                const groupedData = Array.from(groupedDataMap.values());
                //console.log(groupedData);
                // Create heatmap data
                heatmapData = groupedData.reverse();
                setLinechart({
                    options:{
                        dataLabels: {
                            enabled: false
                        },
                        chart: {
                            id: `line-graph-period`,
                            type:"line"
                        },
                        fill: {
                            type: 'solid'
                        },
                        stroke: {
                            show: true,
                            curve: apiDevice?.measurementType !== "digital" ? "smooth": 'stepline',
                            lineCap: 'butt',
                            colors: undefined,
                            width: 2,
                            dashArray: 0, 
                        },
                        xaxis: {
                            categories: categories,
                            type: 'datetime',
                            tickPlacement: 'between',
                            labels: {
                                datetimeUTC: false
                                }
                        },
                        yaxis:{
                            labels: {
                                formatter: function(val) {
                                    return val.toFixed(1);
                                }
                                },
                                title:{
                                text: `Målt i ${apiDevice.measurementTypeUnit}`,
                                },
                        },
                        tooltip:{
                            y:{
                                formatter: function(val){
                                    return `${val.toFixed(2)}${apiDevice.measurementTypeUnit  ? apiDevice.measurementTypeUnit : ''}`
                                }
                            },
                            x: {
                                format: "dd.MM.yyyy - HH:mm"
                                }
                                    
                        },
                    },
                    series: [
                        {
                            name: 'Måling',
                            data: timeseries,
                        },
                    ],
                    
                });
                     /*
                    const values = data.map((d,index) => {
                        if(index === 0){
                            return 0;
                        }
                        if(d.avg_value <-150){
                            return 0
                        }
                        return parseFloat(d.avg_value-data?.[index-1]?.avg_value);
                    });
                    */
    
    
                    // Generate color ranges dynamically
                    const numberOfRanges = 3; // You can change the number of ranges
                    const rangeSize = (maxValue - minValue) / numberOfRanges;
                    const colors = ["#00A100", "#FFB200","#FF0000"]; // Adjust colors as needed
                    //console.log([maxValue,minValue]);
                    const colorRanges = Array.from({ length: numberOfRanges }, (_, i) => {
                        const from = minValue + i * rangeSize;
                        const to = i === numberOfRanges - 1 ? Math.ceil(maxValue) : minValue + (i + 1) * rangeSize - 0.0001; // Ensure non-overlapping ranges
                        const ranges = {
                            from: Math.floor(from),
                            to: Math.floor(to)-0.001,
                            color: colors[i],
                            name: `${parseInt(Math.floor(from))} - ${parseInt(Math.round(to))} ${device?.measurementTypeUnit ? device?.measurementTypeUnit :''}`
                        }
                        return ranges;
                    });
                    setHeatMap({
                        options:{
                            
                            dataLabels: {
                                enabled: false
                                },
                                xaxis: {
                                type: "category",
                                categories: [
                                    0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23
                                    ],
                                    labels:{
                                    formatter: function(val){
                                        const startHour = val;
                                        const endHour = (val + 1) % 24; // Wrap around if endHour exceeds 23
                                
                                        // Format the hours as "HH:00-HH:00"
                                        const formattedLabel = `${startHour.toString().padStart(2, '0')}:00`;
                                
                                        return formattedLabel;
                                
                                    },
                                    offsetX:-5,
    
                                }
                                
                                },
                            /*
                            tooltip:{
                                y:{
                                    formatter: function(val){
                                        return `${val.toFixed(2)}${apiDevice.measurementTypeUnit  ? apiDevice.measurementTypeUnit : ''}`
                                    }
                                }
                            },*/
                            
                            plotOptions: {
                                heatmap: {
                                    colorScale: {
                                    ranges: colorRanges
                                    },
                                },
                            },
                            
                            title: {
                                text: "",
                            },
                            tooltip:{
                                y:{
                                    formatter: function(val){
                                        return `${val.toFixed(2)}${device?.measurementTypeUnit  ? apiDevice.measurementTypeUnit : ''}`
                                    }
                                },
                                x:{
                                    
                                }
                            },
                            
                            },
                        series: heatmapData
                    });
                
                });
            
        })
    }
    const getDevice = async () =>{
        
    } 
    const findFloorAndRoom = (floors,deviceId) => {
        let matches = [];
        // Iterate through each floor
        floors?.forEach(floor => {
            // Iterate through each room in the floor
            floor?.rooms?.forEach((room,index) => {
                // Check if the device is present in the room
                if (room.devices.includes(deviceId)) {
                    matches.push(< span key={"room-name"+index}>{room.name} - {floor.name} <br/></span>);
                }
            });
        });
    
        return matches.length > 0 ? matches : null; 
    };
    const preprocessData = (data) => {
        // Initialize an empty object to store the aggregated values
        const aggregatedData = {};
      
        // Iterate over each data point
        data.forEach((point) => {
          // Extract date and hour from the timestamp
          const timestamp = new Date(point.timestamp);
          const date = timestamp.toISOString().split('T')[0];
          const hour = timestamp.getHours();
      
          // Create a key in the aggregatedData object if it doesn't exist
          if (!aggregatedData[date]) {
            aggregatedData[date] = {};
          }
      
          // Update the aggregated value for the corresponding date and hour
          if (!aggregatedData[date][hour]) {
            aggregatedData[date][hour] = {
              count: 0,
              totalValue: 0,
            };
          }
          aggregatedData[date][hour].count++;
          aggregatedData[date][hour].totalValue += point.avg_value;
        });
      
        // Convert aggregated data to the format suitable for ApexCharts
        const heatmapData = Object.keys(aggregatedData).map((date) => ({
          name: date,
          data: Object.keys(aggregatedData[date]).map((hour) => ({
            x: parseInt(hour),
            y: new Date(date),
            value: aggregatedData[date][hour].totalValue / aggregatedData[date][hour].count,
          })),
        }));
      
        return heatmapData;
      }
    
    const getBuilding = async (buildingId) => {
        const accessToken = await getToken();
        axios.get(`${process.env.REACT_APP_API_URL}/v1/buildings/${buildingId}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(res => {
            const apiBuilding = res.data;
            setBuilding(apiBuilding);
            //console.log(res.data);
        });
    }

    useEffect(() => {
        getDevice();
        getDeviceIotData(dateFilter.startDate, dateFilter.endDate );
    }, [deviceId]);
    useEffect(()=> {
        getDeviceIotData(dateFilter.startDate, dateFilter.endDate );
    },[dateFilter ])
    

    const handleShowDelete = () => {
        setShowDelete(!showDelete);
    }

    const handleDeleteDevice = async (e) => {
        e.preventDefault();
        const accessToken = await getToken();

        try {
            // Send a POST request with Axios
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/devices/${deviceId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            console.log('Response:', response.data);
            
            // Construct the redirect URL
            const redirectUrl = `/devices/`;
            console.log('Skal videresendes til:', redirectUrl);

            // Use the navigate function to redirect to the new URL
            navigate(redirectUrl);
        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
        }
    }
    const getMeasurementType= (mtype) => {
        let unitType = "";
        let typeText = "";
        if(mtype === "temp") {
            typeText = "Sanntidsmåling";
        } else if(mtype === "energy") {
            typeText = "Akkumulert";
        }else if (mtype === "digital"){
            typeText = "Digital"
        }
        return typeText
    }
    return(
        <div className="container-fluid space">
            <Row>
                <Card title={"Sensor"} variant="light" size="8">
                    <h3>{device.name}</h3>
                    
                    <p>{device.description}</p>
                    <ul className="device-info">
                        <li><strong>{device.sensorId}</strong></li>
                        <li>Topic: {device.topic}</li>
                        <li>Målenhet: {getMeasurementType(device.measurementType)} ({device.measurementTypeUnit})</li>
                        
                        <li>Siste måling: {  device?.lrv } {device?.measurementType === "energy" ? '(Δ ' +device?.ldv + ')' :'' } { device?.lrv ? device.measurementTypeUnit:''}</li>
                    </ul>
                    
                </Card>

                <Card variant="light" size="4">
                    <div className="circle-company">
                        <div className="circle-icon">
                            <i className="fa-sharp fa-solid fa-building-memo"></i>
                        </div>

                        <h3>{building?.name}</h3>
                        <p>{building?.address}, {building?.city}</p>
                        <p style={{fontSize:'14px'}}>
                        {findFloorAndRoom(building.floors,device._id)}
                        </p>
                    </div>
                </Card>
            </Row>

            <Row mTop="25px" mBot="25px">
                
                <Card variant="light" title="Heatmap av periode" size={"6"}>
                    {heatmap?.options !== undefined && 
                        <Chart
                        options={heatmap.options}
                        series={heatmap.series}
                        type="heatmap"
                        width="100%"
                        />
                    }
                </Card>

                <Card variant="light" title="Data i perioden" size={"6"}>
                    {linechart?.options && linechart?.series !== undefined &&
                     <Chart
                        options={ linechart.options}
                        series={linechart.series}
                        type="line"
                        width="100%"
                     />
                    }
                </Card>
            </Row>

            <Row mTop="25px" mBot="25px">
                <Card title={"Alarmhistorikk"} variant="light" size="12">
                    
                        <DeviceAlarmHistory key={`alarm-history`} device={device}/>
                </Card>
            </Row>

            <div className="col-sm-12">
                <button onClick={handleShowDelete} className="button action-delete">Slett enhet</button>
                <Link to={"/buildings/" + building._id + "/devices/" + deviceId} className="button create">Rediger</Link>

                {showDelete ?
                <>
                    <div className="deleteModal">
                        <div className="deleteHeader">
                            <h2>Slett enhet?</h2>
                        </div>

                        <div className="deleteContent">
                            <p>Er du sikker på at du ønsker å slette enhet: "{device.name}"?</p>
                            <div className="buttonwrap">
                                <button onClick={handleShowDelete} className="button default">Avbryt</button>
                                <button onClick={handleDeleteDevice} className="button danger">Slett enhet</button>
                            </div>
                        </div>
                    </div>
                </>
            : ""}
            </div>
        </div>
    )
    
}

export default Device;