import React from 'react'
import Chart from "react-apexcharts";
import energyCategoryColors from '../energyCategoryColors';

export default function EnergyBarRankingChart ({ data }){
  //console.log("rendernow")
  if(data ===undefined )return <></>;
  const chartData = {
    series: [{
      name: 'Energiforbruk / BRA',
      data: data.map(item => item.totalEnergyUsagePerBra.toFixed(2))
    }],
    options: {
      chart: {
        type: 'bar',
        id:"pie-chart-energy"
      },
      plotOptions: {
        bar: {
          horizontal: true,
          
        },
      },
      xaxis: {
        categories: data.map(item => item.name),
        title:{
          text:"kWh / m2"
        }
      },
      tooltip:{
          y:{
              formatter: function(val){
                  return `${val.toFixed(0)} kWh/m²`
              }
          },
      },
      noData: {
          text: "Laster inn",
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        }
    }
  };
  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};
