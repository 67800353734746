import React from "react";
import Card from "./card";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";

function Callback() {
    const {user} = useKindeAuth();
    console.log(user);

    return(
        <div className="container-fluid">
            <div className="row">
                <Card title="Callback" type="warning" size="12">
                    <p>Hei!</p>;
                </Card>     
            </div>
        </div>
    );
}

export default Callback;