const energyCategoryColors = [
  '#D63636', // Oppvarming
  '#3636D6', // Varmtvann
  '#D68836', // Vifter og pumper
  '#D6D136', // Belysning
  '#9E36D6', // Teknisk utstyr
  '#2DACBD', // Kjøling
  '#43D636', // Utendørs
  '#7d9099'  // Ukategorisert
];

export default energyCategoryColors;