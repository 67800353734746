import React, { useState, useEffect } from "react";
import Card from "../components/card";
import axios from "axios";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../GlobalStateContext";


function CreateAlarm() {

    const navigate = useNavigate();
    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState([]);
    const [selectBuildingApi, setSelectBuildingApi] = useState([]);
    const { getToken } = useKindeAuth();
    const { aquaflowUser } = useGlobalState();
    const [formData, setFormData] = useState({
        // Initialize form fields here
        organization:'',
        device: '',
        name: '',
        description: '',
        tempLimits: {
            min: '',
            max: '',
        },
        alarmType: '',
        delayBeforeTriggeredInMinutes: 0,
        deadband: 0,
        triggered: 'false',
        enabled: 'true',
        status: '',
        priority: '',
        alertList:   ((aquaflowUser?.user?.email || aquaflowUser?.user?.phone)) ? [{
            name:aquaflowUser?.user?.name,
            email:aquaflowUser?.user?.email,
            phone: aquaflowUser?.user?.phone,
            notifyEmail:true,
            notifySMS:false
        }]:[] 
        ,
      });

    const getBuildings = async() =>{
        const accessToken = await getToken();
        axios.get(process.env.REACT_APP_API_URL+'/v1/buildings', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(res => {
                const apiBuildings = res.data;
                setBuildings(apiBuildings);
                setSelectedBuilding(apiBuildings[0]);
                
        });
    }

    useEffect(() => {
        getBuildings();
    }, []);

    const handleBuildingChange = async(e) => {
        const id = e.target.value;
        
        if(id){
            const foundBuilding = buildings.find(building => building._id === id);
            if(foundBuilding){
                setSelectBuildingApi(foundBuilding);
                setFormData({ ...formData, organization: foundBuilding.organization?.[0]?._id });
            }
            //setSelectBuildingApi(res.data);
        }
            
            
    };

    const handleFormSubmit = async (e) => {
        const accessToken = await getToken();

        try {
            // Send a POST request with Axios
            const response = await axios.post(process.env.REACT_APP_API_URL+'/v1/alarms', formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            // Construct the redirect URL
            const redirectUrl = `/alarms/`;

            // Use the navigate function to redirect to the new URL
            navigate(redirectUrl);
        } catch (error) {
            // Handle errors here
            console.error('Error:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Check if the input field is part of the tempLimits object
        if (name.startsWith("tempLimits")) {
            // Extract the nested property (min or max)
            const tempLimitsProperty = name.split(".")[1];

            // Update the tempLimits object with the new value
            setFormData((prevData) => ({
            ...prevData,
            tempLimits: {
                ...prevData.tempLimits,
                [tempLimitsProperty]: value,
            },
            }));
        }else if (name.startsWith("device")){
            const device = selectBuildingApi?.devices?.find((device)=> (device._id === value))
            setFormData((prevData) => ({
                ...prevData,
                device: value,
                alarmType :device?.measurementType ?device.measurementType : "temp",
            }));
            
        } else {
            // If it's not part of tempLimits, update the state normally
            setFormData({ ...formData, [name]: value });
        }


    };
    const handleAlertListChange = (index, field, value) => {
        const updatedAlertList = formData.alertList.map((item, i) => 
            i === index ? { ...item, [field]: value } : item
        );
        setFormData({ ...formData, alertList: updatedAlertList });
    };

    const handleAddAlert = () => {
        setFormData(prevData => ({
            ...prevData,
            alertList: [...prevData.alertList, { name: '', phone: '', email: '', jobTitle: '', company: '', notifyEmail: false, notifySMS: false }]
        }));
    };

    const handleRemoveAlert = (index) => {
        setFormData(prevData => ({
            ...prevData,
            alertList: prevData.alertList.filter((_, i) => i !== index)
        }));
    };


    return(
        <div className="container-fluid">
            <div className="row">
                <Card variant="light" title={"Opprett alarm"} size="12">
                    <form onSubmit={handleFormSubmit} className="create-building-form" id="createBuildingForm">
                        <div className="triple-form">
                            <h3>Alarm</h3>

                            <label htmlFor="name"><i className="fa-sharp fa-light fa-database"></i> Navn på alarm</label>
                            <input onChange={handleInputChange} type="name" name="name" className="form-control" />

                            <label htmlFor="alarmType"><i className="fa-sharp fa-light fa-circle-info"></i> Type</label>
                            <select onChange={handleInputChange} value={formData.alarmType}className="form-select" name="alarmType">
                                <option value="" readonly disabled>Velg type...</option>
                                <option value="temp">Sanntidsmåling</option>
                                <option value="energy">Akkumulert</option>
                                <option value="digital">Digital (1/0)</option> {/* Add other sensor types as needed */}
                            </select>

                            <label htmlFor="priority"><i className="fa-sharp fa-light fa-star"></i> Prioritet</label>
                            <input onChange={handleInputChange} type="number" name="priority" className="form-control" defaultValue="1" />
                            <p className="small-text">Lavere tall er høyere prioritet.</p>

                            <label htmlFor="description"><i className="fa-sharp fa-light fa-circle-info"></i> Beskrivelse</label>
                            <textarea onChange={handleInputChange} name="description" className="form-control" placeholder="Skriv en beskrivelse her..."></textarea>
                        </div>

                        <div className="triple-form">
                            <h3>Detaljer</h3>

                            <label htmlFor="tempLimits.min"><i className="fa-sharp fa-regular fa-circle-arrow-down"></i> Min</label>
                            <input onChange={handleInputChange} type="number" className="form-control" name="tempLimits.min" defaultValue="0" />

                            <label htmlFor="tempLimits.max"><i className="fa-sharp fa-regular fa-circle-arrow-up"></i> Max</label>
                            <input onChange={handleInputChange} type="number" className="form-control" name="tempLimits.max" defaultValue="0" />

                            <label htmlFor="deadband"><i className="fa-sharp fa-light fa-arrow-up-right-and-arrow-down-left-from-center"></i> Deadband</label>
                            <input onChange={handleInputChange} type="number" className="form-control" step="0.1" name="deadband" defaultValue="0.1" />

                            <label htmlFor="delayBeforeTriggeredInMinutes"><i className="fa-sharp fa-light fa-clock"></i> Delay (minutter)</label>
                            <input onChange={handleInputChange} type="number" className="form-control" step="1" name="delayBeforeTriggeredInMinutes" defaultValue="1" />
                        </div>

                        <div className="triple-form">
                            <h3>Sensor</h3>
                            <label htmlFor="building"><i className="fa-sharp fa-light fa-building"></i> Bygg</label>
                            <select onChange={handleBuildingChange} className="form-select" name="building">
                                <option readonly disabled selected value="">Velg bygg...</option>

                                {buildings.map((building, i) => (
                                    <option key={building._id} value={building._id}>
                                        {building.name}
                                    </option>
                                ))}
                            </select>

                            <label htmlFor="device"><i className="fa-sharp fa-light fa-database"></i> Sensor</label>
                            <select onChange={handleInputChange} value={formData.device} className="form-select" name="device">
                                <option hidden value="">Velg sensor...</option>

                                {selectBuildingApi?.devices?.map((device) => (
                                    <option key={device._id} value={device._id}>
                                        {device.name}
                                    </option>
                                ))}
                            </select>
                            <h3 className="mt-3">Alarmmottaker</h3>
                            <div className="contact-row">

                            
                            {formData.alertList.map((contact, index) => (
                                <div key={index} className=" row contact-alert">
                                    
                                    <div className="col-sm-12 mb-1">
                                    <input
                                        type="text"
                                        placeholder="Navn"
                                        value={contact.name}
                                        className="form-control"
                                        onChange={(e) => handleAlertListChange(index, 'name', e.target.value)}
                                    />
                                    </div>
                                    <div className="col-sm-8 mb-1">
                                    <input
                                        type="text"
                                        placeholder="Telefon"
                                        value={contact.phone}
                                        className="form-control"
                                        onChange={(e) => handleAlertListChange(index, 'phone', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="form-check"
                                            checked={contact.notifySMS}
                                            onChange={(e) => handleAlertListChange(index, 'notifySMS', e.target.checked)}
                                            /> Varsling
                                    </label>
                                    </div>
                                    <div className="col-sm-8 mb-1">
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            value={contact.email}
                                            className="form-control"
                                            onChange={(e) => handleAlertListChange(index, 'email', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="form-check"
                                                checked={contact.notifyEmail}
                                                onChange={(e) => handleAlertListChange(index, 'notifyEmail', e.target.checked)}
                                                /> Varsling
                                        </label>
                                    </div>
                                    <button type="button" className="small-text-remove" onClick={() => handleRemoveAlert(index)}><i className="fa-thin fa-trash-can"></i>Fjern</button>
                                </div>
                            ))}
                            </div>
                            <button type="button"  onClick={handleAddAlert} className="small-text-add"><i className="fa-thin fa-regular fa-plus"></i> Legg til kontakt</button>
                        </div>
                    </form>

                </Card>

                <div className="col-sm-12">
                    <button onClick={handleFormSubmit} type="submit" className="button create">Opprett alarm</button>
                </div>
            </div>
        </div>
    );
}

export default CreateAlarm;