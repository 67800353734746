import React, { useState, useEffect } from "react";
import Card from "../components/card";
import axios from "axios";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useNavigate, useParams } from "react-router-dom";

function EditAlarm() {
    const navigate = useNavigate();
    const [buildings, setBuildings] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedBuilding, setSelectedBuilding] = useState({});
    const [alarm, setAlarm] = useState({});
    const { getToken } = useKindeAuth();
    const { alarmId } = useParams();

    const [formData, setFormData] = useState({
        organization: '',
        device: '',
        sensorId: '',
        deviceId: '',
        name: '',
        description: '',
        tempLimits: {
            min: '',
            max: '',
        },
        alarmType: '',
        delayBeforeTriggeredInMinutes: '',
        deadband: '',
        enabled: true,
        status: '',
        priority: '',
        alertList: [],
    });

    const getAlarm = async () => {
        const accessToken = await getToken();
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/alarms/${alarmId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            const apiAlarm = res.data;
            setAlarm(apiAlarm);
            setFormData({
                ...apiAlarm,
                device: apiAlarm.device._id,
                building: apiAlarm.device.building._id
            });

            const buildingRes = await axios.get(`${process.env.REACT_APP_API_URL}/v1/buildings/${apiAlarm.device.building._id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            const selectedBuildingDevices = buildingRes.data.devices;
            // setDevices(selectedBuildingDevices); // Set devices to show if necessary
        } catch (error) {
            console.error('Error fetching alarm data:', error);
        }
    };

    const getBuildings = async () => {
        const accessToken = await getToken();
        try {
            const res = await axios.get(process.env.REACT_APP_API_URL + '/v1/buildings', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            const apiBuildings = res.data;
            setBuildings(apiBuildings);
        } catch (error) {
            console.error('Error fetching buildings data:', error);
        }
    };

    useEffect(() => {
        getBuildings();
        getAlarm();
    }, []);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const accessToken = await getToken();
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/v1/alarms/${alarmId}`, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            navigate('/alarms/');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            if (name.startsWith("tempLimits")) {
                const tempLimitsProperty = name.split(".")[1];
                return {
                    ...prevData,
                    tempLimits: {
                        ...prevData.tempLimits,
                        [tempLimitsProperty]: value,
                    },
                };
            } else {
                return { ...prevData, [name]: value };
            }
        });
    };

    const handleDeviceChange = (e) => {
        const { value } = e.target;
        const building = buildings.filter((building) => (building._id === formData.building));
        const deviceObject = building?.[0]?.devices?.find((device) => (device._id === value));
        console.log(deviceObject)
        if(deviceObject){
            setFormData((prevData) => ({ 
                ...prevData, 
                device: value,
                alarmType :deviceObject?.measurementType ? deviceObject.measurementType : "",
             }));
        }
    };

    const handleBuildingChange = (e) => {
        const id = e.target.value;
        const foundBuilding = buildings.find(building => building._id === id);
        if (foundBuilding) {
            setSelectedBuilding(foundBuilding);
            setFormData({ ...formData, building: id, organization: foundBuilding.organization?.[0]?._id });
        }
    };

    const handleAlertListChange = (index, field, value) => {
        const updatedAlertList = formData.alertList.map((item, i) => 
            i === index ? { ...item, [field]: value } : item
        );
        setFormData({ ...formData, alertList: updatedAlertList });
    };

    const handleAddAlert = () => {
        setFormData(prevData => ({
            ...prevData,
            alertList: [...prevData.alertList, { name: '', phone: '', email: '', jobTitle: '', company: '', notifyEmail: false, notifySMS: false }]
        }));
    };

    const handleRemoveAlert = (index) => {
        setFormData(prevData => ({
            ...prevData,
            alertList: prevData.alertList.filter((_, i) => i !== index)
        }));
    };

    const handleIsDeleting = () => setIsDeleting(true);
    const regretDelete = () => setIsDeleting(false);

    const handleAlarmDelete = async (e) => {
        e.preventDefault();
        const accessToken = await getToken();
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/v1/alarms/${alarmId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            navigate('/alarms/');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Card title={"Rediger " + alarm.name} variant="light" size="12">
                    <form onSubmit={handleFormSubmit} className="create-building-form" id="createBuildingForm">
                        <div className="triple-form">
                            <h3>Alarm</h3>
                            <label htmlFor="name"><i className="fa-sharp fa-light fa-database"></i> Navn på alarm</label>
                            <input onChange={handleInputChange} type="name" name="name" value={formData.name} className="form-control" />

                            <label htmlFor="alarmType"><i className="fa-sharp fa-light fa-circle-info"></i> Type</label>
                            <select value={formData.alarmType} onChange={handleInputChange} className="form-select" name="alarmType">
                                <option value="" disabled>Velg type...</option>
                                <option value="temp">Sanntidsmåling</option>
                                <option value="energy">Akkumulert</option>
                                <option value="digital">Digital (1/0)</option> {/* Add other sensor types as needed */}
                            </select>

                            <label htmlFor="description"><i className="fa-sharp fa-light fa-circle-info"></i> Beskrivelse</label>
                            <textarea onChange={handleInputChange} name="description" className="form-control" value={formData.description}></textarea>
                        </div>

                        <div className="triple-form">
                            <h3>Detaljer</h3>
                            <label htmlFor="tempLimits.min"><i className="fa-sharp fa-regular fa-circle-arrow-down"></i> Min</label>
                            <input onChange={handleInputChange} type="number" className="form-control" name="tempLimits.min" value={formData.tempLimits.min} />

                            <label htmlFor="tempLimits.max"><i className="fa-sharp fa-regular fa-circle-arrow-up"></i> Max</label>
                            <input onChange={handleInputChange} type="number" className="form-control" name="tempLimits.max" value={formData.tempLimits.max} />

                            <label htmlFor="deadband"><i className="fa-sharp fa-light fa-arrow-up-right-and-arrow-down-left-from-center"></i> Deadband</label>
                            <input onChange={handleInputChange} type="number" className="form-control" step="0.1" name="deadband" value={formData.deadband} />

                            <label htmlFor="delayBeforeTriggeredInMinutes"><i className="fa-sharp fa-light fa-clock"></i> Delay (minutter)</label>
                            <input onChange={handleInputChange} type="number" className="form-control" step="1" name="delayBeforeTriggeredInMinutes" value={formData.delayBeforeTriggeredInMinutes} />
                        </div>

                        <div className="triple-form">
                            <h3>Bygg, Sensor og Kontaktliste</h3>
                            <label htmlFor="building"><i className="fa-sharp fa-light fa-building"></i> Bygg</label>
                            <select
                                name="device.building._id"
                                onChange={handleBuildingChange}
                                className="form-select"
                                value={formData.building} // Set default selected value
                            >
                                <option disabled>Velg bygning...</option>
                                {buildings.map((building) => (
                                    <option value={building._id} key={building._id}>
                                        {building.name}
                                    </option>
                                ))}
                            </select>

                            <label htmlFor="device"><i className="fa-sharp fa-light fa-database"></i> Sensor</label>
                            <select
                                name="device._id"
                                onChange={handleDeviceChange}
                                className="form-select"
                                value={formData.device} // Set default selected value
                            >
                                <option disabled>Velg sensor...</option>
                                {buildings.map((building) => (
                                    building._id === formData.building &&
                                    building.devices.map((device) => (
                                        <option value={device._id} key={device._id}>
                                            {device.name}
                                        </option>
                                    ))
                                ))}
                            </select>

                            <h3 className="mt-3">Alarmmottaker</h3>
                            <div className="contact-row">

                            
                            {formData.alertList.map((contact, index) => (
                                <div key={index} className=" row contact-alert">
                                    
                                    <div className="col-sm-12 mb-1">
                                    <input
                                        type="text"
                                        placeholder="Navn"
                                        value={contact.name}
                                        className="form-control"
                                        onChange={(e) => handleAlertListChange(index, 'name', e.target.value)}
                                    />
                                    </div>
                                    <div className="col-sm-8 mb-1">
                                    <input
                                        type="text"
                                        placeholder="Telefon"
                                        value={contact.phone}
                                        className="form-control"
                                        onChange={(e) => handleAlertListChange(index, 'phone', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="form-check"
                                            checked={contact.notifySMS}
                                            onChange={(e) => handleAlertListChange(index, 'notifySMS', e.target.checked)}
                                            /> Varsling
                                    </label>
                                    </div>
                                    <div className="col-sm-8 mb-1">
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            value={contact.email}
                                            className="form-control"
                                            onChange={(e) => handleAlertListChange(index, 'email', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="form-check"
                                                checked={contact.notifyEmail}
                                                onChange={(e) => handleAlertListChange(index, 'notifyEmail', e.target.checked)}
                                                /> Varsling
                                        </label>
                                    </div>
                                    <button type="button" className="small-text-remove" onClick={() => handleRemoveAlert(index)}><i className="fa-thin fa-trash-can"></i>Fjern</button>
                                </div>
                            ))}
                            </div>
                            <button type="button"  onClick={handleAddAlert} className="small-text-add"><i className="fa-thin fa-regular fa-plus"></i> Legg til kontakt</button>
                        </div>
                    </form>
                </Card>

                <div className="col-sm-12">
                    <button onClick={handleIsDeleting} className="button action-delete">Slett alarm</button>
                    <button onClick={handleFormSubmit} type="submit" className="button create">Oppdater alarm</button>
                </div>


            </div>

            {isDeleting ?
                <>
                    <div className="deleteModal">
                        <div className="deleteHeader">
                            <h2>Slett alarm?</h2>
                        </div>

                        <div className="deleteContent">
                            <p>Er du sikker på at du ønsker å slette alarm: "{alarm.name}"?</p>
                            <div className="buttonwrap">
                                <button onClick={regretDelete} className="button default">Avbryt</button>
                                <button onClick={handleAlarmDelete} className="button danger">Slett alarm</button>
                            </div>
                        </div>
                    </div>
                </>
            : ""}
        </div>

        
    );
}

export default EditAlarm;