import React, { useState, useEffect } from "react";
import Card from "../components/card";
import { Link } from "react-router-dom";
import axios from "axios";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

import { useGlobalState } from "../GlobalStateContext";


function Buildings() {

    const { getToken } = useKindeAuth();

    const { dateFilter } = useGlobalState();

    const [buildings, setBuildings] = useState([]);

    const getBuildings = async() =>{
        const accessToken = await getToken();
        axios.get(process.env.REACT_APP_API_URL+'/v1/buildings', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(res => {
            const apiBuildings = res.data;
            setBuildings(apiBuildings);
        });
    }

    useEffect(() => {
        getBuildings();
    }, []);


    return(
        <div className="container-fluid">
            <div className="row">
                <Card variant="light" title="Bygg" size="12">

                <div className="mobile-only">
              {buildings.map((building, i) => {
                    return (
                      <div key={i} className="flex-information">
                          <div className="flex-box">
                            <strong>{building.name}</strong><br />
                            {building.organization?.[0]?.name}
                          </div>

                          <div className="flex-box">
                            {Array.isArray(building.devices) ? building.devices.length : 0} 
                            {building.devices.length >= 2 ? " enheter" : " enhet"} 
                          </div>

                          <div className="flex-action">
                            <Link to={"/buildings/" + building._id} className="select-btn">Velg</Link>
                          </div>
                      </div>

                    );
                  })}
            </div>

                <div className="desktop-only">
                    <table className="infotable">
                        <thead>
                            <tr>
                            <th>Bygg</th>
                            <th>Etasjer</th>
                            <th>BRA</th>
                            <th>BTA</th>
                            <th>Enheter</th>
                            <th>Handlinger</th>
                            </tr>
                        </thead>

                        <tbody>
                        {buildings?.map((building, i) => {
                            return(
                                <tr key={`building-${i}`}>
                                    <td>
                                        <strong>{building.name}</strong><br />
                                        {building.address}
                                    </td>
                                    <td>{building?.floors?.length}</td>
                                    <td>{building?.sizeBra ? (<>{building?.sizeBra} m<sup>2</sup></>) :''}</td>
                                    <td>{building?.sizeBta ? (<>{building?.sizeBta} m<sup>2</sup></>) :''}</td>
                                    <td>{building?.devices?.length}</td>
                                    <td><Link className="select-btn" to={"/buildings/" + building._id}>Velg</Link></td>
                                </tr>
                            );
                        }) }
                        </tbody>
                    </table>
                </div>
                
                </Card>

                <div className="col-sm-12">
                    <Link className="button create" to="/buildings/create">Opprett bygg</Link>
                </div>
            </div>
        </div>
    );
}

export default Buildings;