import React from "react";
import { useState } from "react";
import { Props } from "react";
import { useGlobalState } from "../GlobalStateContext";

const MultiCalendar = (props) => {

    function getCustomDay(date) { // get day number from 0 (monday) to 6 (sunday)
        let day = date.getDay();
        if (day === 0) day = 7; // make Sunday (0) the last day
        return day - 1;
    }

    const [selectedYear, setSelectedYear] = useState(props.year);
    const [selectedMonth, setSelectedMonth] = useState(props.month);

    const monthNames = ["januar", "februar", "mars", "april", "mai", "juni",
    "juli", "august", "september", "oktober", "november", "desember"
    ];

    if(selectedMonth > 12) {
        setSelectedMonth(1);
        setSelectedYear(selectedYear + 1);
    } else if(selectedMonth < 1) {
        setSelectedMonth(12);
        setSelectedYear(selectedYear - 1);
    }

    var dateObj = new Date(selectedYear, selectedMonth - 1, 1, 12);
    var lastDateObj = new Date(selectedYear, selectedMonth, 0, 12);
    
    //console.log(getDay(dateObj))

    const { dateFilter, updateDateFilter, setStartDate, setEndDate } = useGlobalState();

    const selectDate = (year,month,day) => {
        let stringDay = day;

        if (day<10) {
            stringDay = "0" + day;
        }        

        let stringMonth = month;

        if (month<10) {
            stringMonth = "0" + month;
        }

        let testDate = year + "-" + stringMonth + "-" + stringDay;

        if(props.selectingStart) {
            setStartDate(testDate);
        } else {
            setEndDate(testDate);
        }
    }
    
    const setCellColor = (year,month,day) => {
        let stringDay = day;

        if (day<10) {
            stringDay = "0" + day;
        }

        let stringMonth = month;

        if (month<10) {
            stringMonth = "0" + month;
        }

        let testDate = new Date(year + "-" + stringMonth + "-" + stringDay);
        let startDate = new Date(dateFilter.startDate);
        let endDate = new Date(dateFilter.endDate);
        if(testDate.getTime() === startDate.getTime() && (testDate.getTime() === endDate.getTime())){
            return "calendar-start-end"
        }
        else if(testDate.getTime() === startDate.getTime() ){
            
            return "calendar-start"
        }else if(testDate.getTime() > startDate.getTime() && testDate.getTime()<endDate.getTime()){
            return "calendar-between"
        }else if (testDate.getTime() === endDate.getTime()){
            return "calendar-end"
        }
        return "";
    }


    return(
        <div className="custom-calendar" id="customCalendar">

            
            <div className="cal-header">

                <div className="monthChanger">
                    <div onClick={() => setSelectedMonth(selectedMonth - 1)}><i className="fa-duotone fa-circle-chevron-left"></i></div>
                    <div id="monthName">{monthNames[selectedMonth -1]}</div>
                    <div onClick={() => setSelectedMonth(selectedMonth + 1)}><i className="fa-duotone fa-circle-chevron-right"></i></div>
                </div>

                <div className="monthChanger">
                    <div onClick={() => setSelectedYear(selectedYear - 1)}><i className="fa-duotone fa-circle-chevron-left"></i></div>
                    <h3>{selectedYear}</h3>
                    <div onClick={() => setSelectedYear(selectedYear + 1)}><i className="fa-duotone fa-circle-chevron-right"></i></div>
                </div>


            </div>

            <div className="custom-table-wrap">
                <div className="table-header">Man</div>
                <div className="table-header">Tir</div>
                <div className="table-header">Ons</div>
                <div className="table-header">Tor</div>
                <div className="table-header">Fre</div>
                <div className="table-header">Lør</div>
                <div className="table-header">Søn</div>


                    {(() => {
                        const arr = [];
                        for (let i = 0; i < getCustomDay(dateObj); i++) {
                            arr.push(
                                <div className="not-month"></div>
                            );
                        }
                        
                        for (let i = 1; i <= lastDateObj.getDate(); i++) {
                            arr.push(
                                <div 
                                className={
                                    setCellColor(selectedYear,selectedMonth,i)
                                }
                                onClick={()=>{selectDate(selectedYear,selectedMonth,i); }}>{i}</div>
                            )

                           //setDateObj(new Date(dateObj.getDate()+1))
                            
                        }

                        for (let i = 6; i > getCustomDay(lastDateObj); i--) {
                            
                            arr.push(
                                <div className="not-month"></div>
                            );
                        }


                        return arr;
                    })()}
                        
                </div>
        </div>
    );
}




export default MultiCalendar;