import React, { useEffect, useState  } from "react";
import Card from "../components/card";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import axios from 'axios';

function Account() {
    
    const { user, isAuthenticated, getToken , isLoading , getOrganization} = useKindeAuth();
    const [userData, setUserData] = useState(null);

    const { login, register,logout } = useKindeAuth();
    const getUserInfo = async() =>{
        console.log(getOrganization())
        const accessToken = await getToken();
        axios.post(`${process.env.REACT_APP_API_URL}/v1/login-heartbeat`,{
            name : user.given_name + " " + user.family_name,
            email : user.email
        },
        {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
        }).then((res)=> {
            console.log(res.data);
            setUserData(res.data);
           
        })
    }
    useEffect(()=>{
        getUserInfo();
    },[])
    return(
        <div className="container-fluid">
            
            <div className="row justify-content-center">
                <Card variant="light" title={"Hei, " + user.given_name + "!"} size="12">

                    <form className="create-building-form">
                        <div className="triple-form">
                            <h3>Konto</h3>
                            <p>Her finner du informasjonen som er tilknyttet din konto.</p>

                            <label htmlFor="acc-name">Fornavn:</label>
                            <input readOnly type="text" name="acc-name" className="form-control" defaultValue={user.given_name} />
                            
                            <label htmlFor="acc-lastname">Etternavn:</label>
                            <input readOnly type="text" name="acc-lastname"  className="form-control" defaultValue={user.family_name} />

                            <label htmlFor="acc-email">E-post:</label>
                            <input readOnly type="text" name="acc-email"  className="form-control" defaultValue={user.email} />
                        </div>

                        <div className="triple-form">
                            <h3>Organisasjoner</h3>
                            <ul className="org-list">
                                {userData?.user?.organizations?.map((organization, index) => (
                                <li key={organization._id || index}>{organization.name}</li>
                                ))}
                            </ul>
                        </div>                    
                    </form>

                </Card>
            
            </div>
            <div className="row">
                <div className="col-md-3">
                    <button type="button" className="button action-delete" onClick={logout}>Logg ut <i class="fa-light fa-arrow-right-from-bracket"></i></button>
                </div>
            </div>
        </div>
    );
}

export default Account;