import React, { useState , useEffect } from 'react';
import axios from "axios";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Link, useSearchParams } from 'react-router-dom';
import Card from '../components/card';
const nameDictionary = {
  devices:"enhet",
  alarms:"alarm",
  buildings:"bygg"
}

const icoDict = {
  devices:"fa-sharp fa-light fa-sensor",
  alarms:"fa-sharp fa-light fa-bell-on",
  buildings:"fa-sharp fa-light fa-buildings"
}

export default function SearchPage() {
  const [queryParams] = useSearchParams();
  const model = queryParams.get("model");
  const searchString = queryParams.get("s");
  const [searchResults, setSearchResults] = useState('');
  const { getToken } = useKindeAuth();

  const renderHighlight = (highlight) => {
    if (!highlight || !highlight.texts) {
        return null; // Return null if highlight or texts array is not present
    }

    const combinedText = highlight.texts.map((text, index) => {
        if (text.type === "hit") {
            return <span key={index} className="highlight">{text.value}</span>;
        } else {
            return <span key={index}>{text.value}</span>;
        }
    });

    return combinedText;
  }
  useEffect(()=>{
    if(model ===undefined || searchString===undefined || model ==="" || searchString===""){
      setSearchResults('');
      return;
    }
    setSearchResults(
      <div className="search-results">
        <div className="result-line">
          Laster...
        </div>
      </div>
    )
    fetchAllresults(model,searchString)
  },[])
  const fetchAllresults = async (modelStr,query) => {
    const accessToken = await getToken();
    axios.get(`${process.env.REACT_APP_API_URL}/v1/search/by-model?query=${query}&model=${modelStr}`,{ 
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response)=> {
      const data = response.data;
      console.log(response.data);
      if(data.length === 0 ){
        setSearchResults('')
        return
      }
      //console.log(`Highest score (${highestScore}) in ${highestCategory}`);
      //console.log(`Second highest score (${secondHighestScore}) in ${secondHighestCategory}`);
      setSearchResults(
        <div className="search-results large-search">
          <div className='result-header'>
            <div className="result-title my-auto row">
              <div className="col-6">
              <h5 style={{textTransform:'capitalize'}}>
              <i className={`${icoDict[modelStr]}  me-3 `}></i>
              {nameDictionary[modelStr]}
              </h5>
              </div>
              <div className="col-6 text-end">
                {data.length} 
              </div>
            </div>
          </div>
          {data.map((result,index)=> {
            return(
              <Link to={`/${modelStr}/${result._id}`} className="result-line-link" key={`result-search-${index}`}>
              <div className='result-line' >
                <div className='row'>
                  <div className='col-sm-6 result-line-name'>
                    {result.name}
                  </div>
                  <div className='col-sm-6 highlight-wrapper'>
                    {result?.highlights.map((highlight,idx) => (
                      <div className='highlight-row text-end' key={`${result._id}-hl-${idx}`}>
                        '{highlight.path}' : {renderHighlight(highlight)}
                      </div>
                    ))}
                  </div>
                </div>
               </div>    
                </Link>
            )
          })}
      </div>
      )
    }).catch((error)=>{
      console.log(error)
      setSearchResults(
      <div className="search-results failed">
        <div className='result-line fail-message'> 
          Søket for '{query ? query : "\'\'"}' feilet, med tilbakemelding:
          <br/>
          <code>
          {error.message} 
          </code>
        </div>    
      </div>)
    })
  }
  return (
    
    <div className="container-fluid">
      <div className="row">
        <Card variant="light" title={`Søkeresultater for "${searchString ? searchString : "\'\'"}"`} size="12">
          {searchResults ? (
          <>
           {searchResults}
          </>
          ):(
            <div className="search-results">
               <div className='result-line'> 
                  Ingen Resultater 
                </div>    
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}
